
// -----------------------------------------------------------------------------
// + People grid
// -----------------------------------------------------------------------------

.block-people-grid {

  @include block-wrapper($cols: 10);
  @include when-smaller-than('tablet-landscape') { --margin: 1.25rem; }
  @include when-larger-than('tablet-landscape') { --margin: 2.5rem; }

  .block-people-grid__wrapper {
    grid-column: 2 / -2;
  }

  .block-people-grid__items_wrapper {
    grid-column: 2 / -2;
    display: flex;
    flex-flow: row wrap;
    padding: 2rem 0 4rem 0;
    margin: calc(var(--margin) * -1);
    .block-people-grid__card {
      width: 20%;
      min-width: 17rem;
      margin: var(--margin);
    }
  }
}


.person-card {
  .person-card__name {
    margin-bottom: 0;
    @include when-smaller-than('tablet-portrait') {
      margin-top: 1rem;
    }
  }
  .person-card__title {
      margin-top: 0;
      margin-bottom: 2rem;
  }
  .person-card__photo {
    position: relative;
    height: 0;
    width: 100%;
    padding-bottom: 120%;
    img {
      position: absolute;
      top: 0; left: 0;
      width: 100%;
      height: 100%;
      z-index: 1;
    }
    &:after {
      z-index: 0;
      display: block;
      position: absolute;
      content: "";
      background-color: $grey-200;
      top: 0; left: 0;
      width: 100%;
      height: 100%;
      transform: translate(-1.25rem, -1.25rem);
    }
  }
}
