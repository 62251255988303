
// CARD FRAGMENTS
// -------------------------------------------------------

@import "../fragments/cardBase";
@import "../fragments/cardContentGroup";
@import "../fragments/cardThumbnail";
@import "../fragments/cardTitleGroup";
@import "../fragments/cardMeta";
@import "../fragments/cardDate";

// CARD TYPE DEFINITION
// -------------------------------------------------------

@mixin card-ressource--base {
  @include card__base;
  @include card__content-group;
    @include card__thumbnail;
    @include card__title-group;
    @include card__meta;
}

@mixin card-ressource--regular {
  grid-column: span 2;
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: start;
  flex-wrap: nowrap;
  @include card__title-group--sm;
  @include card__meta--sm;
}

@mixin card-ressource--large {
  grid-column: span 4;
  position: relative;
  display: flex;
  flex-direction: row;
  justify-content: start;
  flex-wrap: nowrap;
  @include card__title-group--lg;
  @include card__meta--lg;
  .card__thumbnail {
    width: 50%;
    height: 100%;
    img {
      width: 200%;
    }
  }
  .card__content-group { width: 50%; height: 100%; }
}
