
// -----------------------------------------------------------------------------
// Text label to put before link list for e.g.
// -----------------------------------------------------------------------------

span.nav__label {
  display: block;
  font-family: "Whitney SC A", "Whitney SC B";
  text-transform: lowercase;
  font-size: #{(18 / 12)}rem;
  color: $blue-300;
  font-weight: 500;
  margin-bottom: 0;
  margin-right: 1rem;
  height: 4rem;
  line-height: 3.8rem;
  white-space: nowrap;
}
