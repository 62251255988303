
// -----------------------------------------------------------------------------
// + Color theme options
// -----------------------------------------------------------------------------


$themes: (
  white: (
    background : $white,
    title : $blue-200,
    subtitle : $blue-200,
    body : $blue-200,
    link : $blue-200
  ),
  grey-100: (
    background : $grey-100,
    title : $blue-200,
    subtitle : $blue-200,
    body : $blue-200,
    link : $blue-200
  ),
  gradient-whiteToGrey: (
    background : $white $whiteToGrey,
    title : $blue-200,
    subtitle : $blue-200,
    body : $blue-200,
    link : $blue-200
  ),
  gradient-greyToWhite: (
    background : $grey-100 $greyToWhite,
    title : $blue-200,
    subtitle : $blue-200,
    body : $blue-200,
    link : $blue-200
  ),
  blue-200: (
    background : $blue-200,
    title : $white,
    subtitle : $grey-200,
    body : $white,
    link : $white
  ),
  green-200: (
    background : $green-200,
    title : $blue-200,
    subtitle : $blue-200,
    body : $blue-200,
    link : $blue-200
  ),
  yellow-200: (
    background : $yellow-200,
    title : $blue-200,
    subtitle : $blue-200,
    body : $blue-200,
    link : $blue-200
  ),
  cyan-200: (
    background : $cyan-200,
    title : $white,
    subtitle : $grey-100,
    body : $white,
    link : $white
  ),
  orange-200: (
    background : $orange-200,
    title : $white,
    subtitle : $grey-100,
    body : $white,
    link : $white
  )
);

@function getColor($color, $what) {
  @return map-get(map-get($themes, $color), $what);
}

@each $color, $map in $themes {

  .background--#{$color} {
    @include when-larger-than('tablet-landscape') {
      background: getColor($color, background);
      .ttl-combo__title.h2 {      color: getColor($color, title);}
      .ttl-combo__subtitle.h2 {   color: getColor($color, subtitle);}
      .ttl-combo__intro {         color: getColor($color, body);}
      .cta {                      color: getColor($color, link);}
    }
  }
  .text--#{$color} {
    .ttl-combo__title.h2 {      color: getColor($color, title);}
    .ttl-combo__subtitle.h2 {   color: getColor($color, subtitle);}
    .ttl-combo__intro {         color: getColor($color, body);}
    .cta {                      color: getColor($color, link);}
  }
}
