@mixin card__thumbnail {

  .card__thumbnail {
    display: block;
    position: relative;
    background-image: none;
    text-shadow: unset;
    overflow: hidden;
    width: 100%;
    height: 0;
    padding-bottom: 50%;
    opacity: 1;
  }

  .card__thumbnail img {
    display: block;
    position: absolute;
    object-fit: cover;
    width: 100%;
    height: 100%;
    top: 0; right: 0;
    transition: transform 350ms 100ms ease-in-out;
    transform: translateZ();
    opacity: 1;
  }

  @include when-larger-than('tablet-landscape') {
    &:hover .card__thumbnail img {
      transform: scale(1.03);
    }
  }

}
