

.author-bio {

  @include block-wrapper($cols: 10);
  @include block-spacing($place: 'top', $values: 4 4 8);
  @include block-spacing($place: 'bottom', $values: 3 3 3);

  .author-bio__wrapper {
    grid-column: 2 / -2;
    display: flex;
    flex-flow: row wrap;
    .author-bio__left {
      flex: 1;
      max-width: 30rem;
      min-width: 20rem;
      margin-right: 2.5rem;
      margin-bottom: 2.5rem;
    }
    .author-bio__right {
      flex: 1 1 auto;
      min-width: 20rem;
      max-width: 50rem;
    }
  }
}

.author-bio__name {
  @include when-smaller-than('tablet-landscape') {
    br { display: none; }
  }
}
.author-bio__bio {
  max-width: 40rem;
}
.author-bio__image {
  position: relative;
  height: 0;
  width: 100%; // 360px
  padding-bottom: 120%; // 432px
  img {
    position: absolute;
    top: 0; left: 0;
    width: 100%;
    height: 100%;
    z-index: 1;
  }
  &:after {
    z-index: 0;
    display: block;
    position: absolute;
    content: "";
    background-color: $grey-200;
    top: 0; left: 0;
    width: 100%;
    height: 100%;
    transform: translate(-1.25rem, -1.25rem);
  }
}
