
$white: #FFFFFF;
$black: #000000;

$grey-400: #E9E9E9;
$grey-300: #EEEEEE;
$grey-200: #F4F4F4;
$grey-100: #F8F8F8;

$blue-300: #093C53;
$blue-200: #174E67;
$blue-100: #1D6281;

$green-300: #15C972;
$green-200: #5EDA9E;
$green-100: #87E4B7;

$cyan-300: #16AAB5;
$cyan-200: #5BBEC6;
$cyan-100: #84CED4;

$orange-300: #F26930;
$orange-200: #F2875F;
$orange-100: #F8B497;

$yellow-300: #EAB000;
$yellow-200: #FFD245;
$yellow-100: #FFE186;

$pink-300: #F7868B;
$pink-200: #FCCED0;
$pink-100: #FFE3E4;

$text-base-color : $blue-200;
// $text-base-color : #334E67;
$text-light-color : $blue-200;

$link-decor-color : $green-300;
$link-hover-color: $blue-200;

$bg-dark-color : $blue-200;
$bg-light-color: $grey-100;

$whiteToGrey : linear-gradient(
  -180deg,
  #FFFFFF 0%,
  #FFFFFF 9%,
  #FFFFFF 17%,
  #FEFEFE 25%,
  #FEFEFE 31%,
  #FDFDFD 37%,
  #FCFCFC 43%,
  #FCFCFC 49%,
  #FBFBFB 55%,
  #FAFAFA 61%,
  #FAFAFA 67%,
  #F9F9F9 74%,
  #F8F8F8 81%,
  #F8F8F8 90%,
  #F8F8F8 100%
);
$greyToWhite : linear-gradient(
  0deg,
  #FFFFFF 0%,
  #FFFFFF 9%,
  #FFFFFF 17%,
  #FEFEFE 25%,
  #FEFEFE 31%,
  #FDFDFD 37%,
  #FCFCFC 43%,
  #FCFCFC 49%,
  #FBFBFB 55%,
  #FAFAFA 61%,
  #FAFAFA 67%,
  #F9F9F9 74%,
  #F8F8F8 81%,
  #F8F8F8 90%,
  #F8F8F8 100%
);
