html {
  font-size: 12px;
  @include when-smaller-than('tablet-portrait') {
    font-size: 12px;
  }
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-rendering: optimizeLegibility;
}

body {
  font-family: "Whitney A", "Whitney B";
  font-style: normal;
  font-weight: 400;
  font-style: normal;
  font-size: #{(18 / 12)}rem;
  color: $text-light-color;
  line-height: 2rem;
}

// Heading font styles
// ---------------------------------------------------------------------------

.d1 {
  font-family: "Archer A", "Archer B";
  font-weight: 300;
  color: $blue-200;
  em, strong, b, .bold {
    color: $blue-300;
    font-weight: 500!important;
  }
}

h1, .h1,
h2, .h2 {
  font-family: "Archer A", "Archer B";
  font-weight: 400;
  color: $text-base-color;
  em, strong, b, .bold {
    font-weight: 600!important;
  }
}

h3, .h3 {
  font-family: "Whitney A", "Whitney B";
  font-weight: 600;
  color: $text-base-color;
  em, strong, b, .bold {
    font-weight: 700!important;
  }
}

h3.alt, .h3-alt, .h3.alt {
  font-family: "Archer A", "Archer B";
  font-weight: 400;
  color: $text-base-color;
  em, strong, b, .bold {
    font-weight: 600!important;
  }
}


h4, .h4,
h5, .h5,
h6, .h6 {
  font-family: "Whitney A", "Whitney B";
  font-weight: 500;
  color: $text-base-color;
  letter-spacing: 0.2px;
  em, strong, b, .bold {
    font-weight: 700!important;
  }
}
// Heading font sizes
// ---------------------------------------------------------------------------

.d1 {
  font-size: #{(54 / 12)}rem;
  line-height: 5rem;
  margin: 2rem 0 2rem 0;
  @include when-smaller-than('tablet-portrait') {
    font-size: #{(48 / 12)}rem;
    line-height: 4rem;
  }
}

h1, .h1 {
  font-size: #{(42 / 12)}rem;
  line-height: 4rem;
  margin: 2rem 0 2rem 0;
  @include when-smaller-than('tablet-portrait') {
    font-size: #{(36 / 12)}rem;
    line-height: 3.5rem;
  }
}

h2, .d2 {
  font-size: #{(33 / 12)}rem;
  line-height: 3rem;
  margin: 2rem 0 1rem 0;
}

h2, .h2 {
  font-size: #{(33 / 12)}rem;
  line-height: 3rem;
  margin: 2rem 0 1rem 0;
}

h3, .h3 {
  font-size: #{(21 / 12)}rem;
  line-height: 2.5rem;
  margin: 2rem 0 1rem 0;
}

h3.alt, .h3-alt, .h3.alt {
  font-size: #{(24 / 12)}rem;
  line-height: 2rem;
  margin: 2rem 0 1rem 0;
}

h4, .h4 {
  font-size: #{(18 / 12)}rem;
  line-height: 2rem;
  margin: 1rem 0 0 0;
  padding: 1rem 0 0 0;
}

h5, .h5,
h6, .h6 {
  font-size: #{(16 / 12)}rem;
  line-height: 2rem;
  margin: 1rem 0 0 0;
  padding: 0.5rem 0 0 0;
}

h2 + h3 {
  margin-top: 0;
}

h3 + h4 {
  margin-top: -1.5rem;
}


// Other typographic elements
// ---------------------------------------------------------------------------

p {
  margin: 1.5rem 0;
}

div {
  & > p:first-child,
  & > h1:first-child,
  & > h2:first-child,
  & > h3:first-child,
  & > h4:first-child,
  & > h5:first-child,
  & > h6:first-child {
    margin-top: 0;
    padding-top: 0;
  }
  & > p:last-child {
    margin-bottom: 0;
    padding-bottom: 0;
  }
}

.smallcaps, .sc {
  font-family: "Whitney SC A", "Whitney SC B";
  font-style: normal;
  font-weight: 400;
}

.upper, .uppercase {
  font-family: "Whitney SC A", "Whitney SC B";
  font-style: normal;
  font-weight: 400;
  text-transform: lowercase;
  font-size: #{(17 / 12)}rem;
  letter-spacing: 0.5px;
}

em, strong, b, .bold {
  font-weight: 600!important;
}

small, .small {
  font-size: #{(16 / 12)}rem;
}

.xs, .xsmall {
  font-size: #{(13 / 12)}rem;
}

.light-text {
  opacity: 0.75;
}

div.inline > * {
  display: inline;
}

div.title-group,
div.title {
  margin-bottom: 2rem;
  & > * {
    display: inline;
    margin: 0;
    padding: 0;
  }
}

figure {
  margin: 0;
}

hr {
  border: 1px solid darken($grey-400, 5%);
  border-left: 0;
  border-right: 0;
  border-top: 0;
  margin: 2rem 0;
}


ul, ol {
  padding-left: 2rem;
}

ul.phantom, ol.phantom {
  list-style: none;
  padding: 0;
  margin: 0;
}

ul.inline, ol.inline {
  li {
    display: inline;
  }
}

ol {
  list-style-type: decimal-leading-zero;
  li {
    padding-left: 2rem;
    margin-bottom: 1.5rem;
  }
}

p.adresse {
  margin-top: 0;
  margin-bottom: 2rem;
  span {
    font-size: #{(14 / 12)}rem;
    line-height: 1.5rem;
  }
  [property='schema:name'] {
    font-size: #{(16 / 12)}rem;
    line-height: 2rem;
    display: inline-block;
    font-weight: 600;
    color: $blue-300;
    //padding-bottom: 1rem;
  }
  [property='schema:streetAddress'] {}
  [property='schema:postalCode'] {}
  [property='schema:addressLocality'] {}
  [property='schema:addressCountry'] {}
}

// Pills and badges
// ---------------------------------------------------------------------------

.badge {
  cursor: default;
  font-size: #{(12 / 12)}rem;
  font-weight: 500;
  line-height: 1rem;
  border-radius: 10rem;
  padding: .2rem .6rem .3rem .6rem;
  margin-left: 0.5rem;
  display: inline-block;
  text-align: center;
  white-space: nowrap;
  vertical-align: baseline;
  &.badge-dark {
    background-color: rgba(darken($blue-300, 5%), 0.7);
    color: rgba($cyan-100, 1);
  }
}

.pill-holder {
  list-style: none;
  margin: 0;
  padding: 0;
  li {
    display: inline-block;
  }
}

.pill {
  position: relative;
  display: inline-block;
  padding: .5rem 1rem;
  margin: 0.5rem 0.5rem 0.5rem 0;
  font-size: 75%;
  font-weight: 600;
  line-height: 1;
  text-align: center;
  white-space: nowrap;
  vertical-align: baseline;
  border-radius: 10rem;
  background-color: $grey-400;
  &--has-icon {
    padding-left: 2.5rem;
  }
  .pill-icon {
    position: absolute;
    pointer-events: none;
    width: 1.5rem;
    height:1.5rem;
    top: 0.27rem;
    left: 0.66rem;
    svg {
      fill: $green-300;
      width: 100%;
      height: 100%;
    }
  }
}

a.pill {
  background-image: none;
  text-shadow: none;
  &:hover {
    background-image: none;
    text-shadow: none;
    background-color: $green-200;
    .pill-icon svg {
      fill: $blue-200;
    }
  }
}

// Other font styles
// ---------------------------------------------------------------------------

@mixin font-style-meta {
  color: $blue-300;
  font-family: "Whitney SC A", "Whitney SC B";
  font-style: normal;
  font-weight: 300;
  text-transform: uppercase;
  font-size: #{(14 / 12)}rem;
  letter-spacing: 0.5px;
  span, p {
    margin: 0;
    padding: 0;
  }
  a {
    font-weight: 600;
    background-image: none;
    text-shadow: none;
    &:hover {
      background-image: none;
      text-shadow: none;
      color: $green-300;
    }
  }
}

.meta { @include font-style-meta; }

@mixin font-style-meta-title {
  @include font-style-meta;
  font-size: #{(14 / 12)}rem;
  letter-spacing: 0.6px;
  font-weight: 500;
}

.meta-title { @include font-style-meta-title; }

@mixin font-style-date {
  font-family: "Whitney A", "Whitney B";
  font-weight: 900;
  font-size: #{(32 / 12)}rem;
  color: $text-base-color;
  letter-spacing: 0;
  line-height: 36px;
  text-transform: capitalize;
}
