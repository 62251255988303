// + Basic links
// ---------------------------------------------------------------------

a {
  @include link-style-base;
  @include link-style--green-line;
  &:hover { @include link-hover--green-line; }
  &:focus { @include link-focus; }
}

a.unstyle, a.no-style {
  background-image: none;
  text-shadow: unset;
  &:before {
    display: none;
  }
}

a.basic {
  background-image: none;
  text-shadow: unset;
  &:hover {
    text-decoration: underline;
  }
  &:before {
    display: none;
  }
}

a.slide-up {
  @include link-style-slide-up;
}

// + Title links
// ---------------------------------------------------------------------

a > h1, a > .h1,
a > h2, a > .h2,
a > h3, a > .h3,
a > h4, a > .h4,
a > h5, a > .h5 {
  @include link-style-base;
  @include link-style--no-line;
  background-position: 0 100%,110% 95%,0 100%;
  background-size: 2px 1px,2px 1px,1px 2px;
}

a:hover > h1, a:hover > .h1,
a:hover > h2, a:hover > .h2,
a:hover > h3, a:hover > .h3,
a:hover > h4, a:hover > .h4,
a:hover > h5, a:hover > .h5 {
  @include link-style--green-line;
}

a:focus > h1, a:focus > .h1,
a:focus > h2, a:focus > .h2,
a:focus > h3, a:focus > .h3,
a:focus > h4, a:focus > .h4,
a:focus > h5, a:focus > .h5 {
  @include link-style--green-line;
}

a:active > h1, a:active > .h1,
a:active > h2, a:active > .h2,
a:active > h3, a:active > .h3,
a:active > h4, a:active > .h4,
a:active > h5, a:active > .h5 {
  @include link-style--green-line;
}
