

// -----------------------------------------------------------------------------
// Entry navigation used on entry index pages and taxonomy pages
// -----------------------------------------------------------------------------

.pagination {

  display: flex;
  flex-flow: row wrap;
  justify-content: center;
  margin: -1rem;
  padding: 2rem 0;

  .nav__button {
    flex: 0 0 auto;
    height: 4rem;
    margin: 1rem;
  }

}
