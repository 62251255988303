
// -----------------------------------------------------------------------------
// + Hero bloc
// -----------------------------------------------------------------------------

// + Shared styles
// ----------------

.hero-block {

  display: grid;
  position: relative;

  @include when-larger-than('any') {
    grid-template-rows: [top-start] 5rem [image-start] 5rem [middle-start] minmax(10rem, 1fr) [top-end bottom-start] minmax(10rem, 1fr) [middle-end] 5rem [image-end] 5rem [bottom-end];
    grid-template-columns: minmax(2.5rem, auto) [left-start] minmax(0, 5rem) [image-start] minmax(0, 50rem) [left-end right-start] minmax(0, 50rem) [image-end] minmax(0, 5rem) [right-end] minmax(2.5rem, auto);
  }
  @include when-larger-than('tablet-portrait') {
    grid-template-rows: [top-start] 5rem [image-start] 5rem [middle-start] minmax(10rem, 1fr) [top-end bottom-start] minmax(10rem, 1fr) [middle-end] 5rem [image-end] 5rem [bottom-end];
    grid-template-columns: minmax(5rem, auto) [left-start] minmax(0, 5rem) [image-start] minmax(0, 50rem) [left-end right-start] minmax(0, 50rem) [image-end] minmax(0, 5rem) [right-end] minmax(5rem, auto);
  }
  @include when-larger-than('tablet-landscape') {
    grid-template-rows: [top-start] 5rem [image-start] 5rem [middle-start] minmax(14rem, 1fr) [top-end bottom-start] minmax(14rem, 1fr) [middle-end] 5rem [image-end] 5rem [bottom-end];
    grid-template-columns: minmax(5rem, auto) [left-start] minmax(0, 5rem) [image-start] minmax(0, 50rem) [left-end right-start] minmax(0, 50rem) [image-end] minmax(0, 5rem) [right-end] minmax(5rem, auto);
  }
  @include when-larger-than('desktop') {
    grid-template-rows: [top-start] 10rem [image-start] 6rem [middle-start] minmax(14rem, 1fr) [top-end bottom-start] minmax(14rem, 1fr) [middle-end] 6rem [image-end] 10rem [bottom-end];
    grid-template-columns: minmax(5rem, auto) [left-start] minmax(0, 10rem) [image-start] minmax(0, 50rem) [left-end right-start] minmax(0, 50rem) [image-end] minmax(0, 10rem) [right-end] minmax(5rem, auto);
  }

  @include when-larger-than('any') {

    .hero-block__image { grid-area: 4 / 2 / -1 / -2 }

    &.hero-block--topLeft .hero-block__content,
    &.hero-block--bottomLeft .hero-block__content,
    &.hero-block--middleLeft .hero-block__content,
    &.hero-block--topRight .hero-block__content,
    &.hero-block--bottomRight .hero-block__content,
    &.hero-block--middleRight .hero-block__content {
      grid-area: 1 / 2 / 4 / -2;
    }

  }

  @include when-larger-than('tablet-landscape') {

    .hero-block__image { grid-area: image-start / image-start / image-end / image-end }

    &.hero-block--topLeft .hero-block__content      { grid-area: top-start / left-start / top-end / left-end; }
    &.hero-block--bottomLeft .hero-block__content   { grid-area: bottom-start / left-start / bottom-end / left-end; }
    &.hero-block--middleLeft .hero-block__content   { grid-area: middle-start / left-start / middle-end / left-end; }

    &.hero-block--topRight .hero-block__content     { grid-area: top-start / right-start / top-end / right-end; }
    &.hero-block--bottomRight .hero-block__content  { grid-area: bottom-start / right-start / bottom-end / right-end; }
    &.hero-block--middleRight .hero-block__content  { grid-area: middle-start / right-start / middle-end / right-end; }

  }

}

.spacing-top--12 [class*="hero-block--top"] { @include block-spacing($type: 'padding', $place: 'top', $values: 4 6 12); }
.spacing-top--10 [class*="hero-block--top"] { @include block-spacing($type: 'padding', $place: 'top', $values: 4 6 10); }
.spacing-top--8 [class*="hero-block--top"]  { @include block-spacing($type: 'padding', $place: 'top', $values: 4 6 8);  }
.spacing-top--6 [class*="hero-block--top"]  { @include block-spacing($type: 'padding', $place: 'top', $values: 4 6 6);  }
.spacing-top--4 [class*="hero-block--top"]  { @include block-spacing($type: 'padding', $place: 'top', $values: 4 4 4);  }
.spacing-top--3 [class*="hero-block--top"]  { @include block-spacing($type: 'padding', $place: 'top', $values: 3 3 3);  }
.spacing-top--2 [class*="hero-block--top"]  { @include block-spacing($type: 'padding', $place: 'top', $values: 2 2 2);  }
.spacing-top--0 [class*="hero-block--top"]  { @include block-spacing($type: 'padding', $place: 'top', $values: 0 0 0);  }

.spacing-top--12 [class*="hero-block--top"] { @include block-spacing($type: 'margin', $place: 'top', $values: -4 -6 -12); }
.spacing-top--10 [class*="hero-block--top"] { @include block-spacing($type: 'margin', $place: 'top', $values: -4 -6 -10); }
.spacing-top--8 [class*="hero-block--top"]  { @include block-spacing($type: 'margin', $place: 'top', $values: -4 -6 -8);  }
.spacing-top--6 [class*="hero-block--top"]  { @include block-spacing($type: 'margin', $place: 'top', $values: -4 -6 -6);  }
.spacing-top--4 [class*="hero-block--top"]  { @include block-spacing($type: 'margin', $place: 'top', $values: -4 -4 -4);  }
.spacing-top--3 [class*="hero-block--top"]  { @include block-spacing($type: 'margin', $place: 'top', $values: -3 -3 -3);  }
.spacing-top--2 [class*="hero-block--top"]  { @include block-spacing($type: 'margin', $place: 'top', $values: -2 -2 -2);  }
.spacing-top--0 [class*="hero-block--top"]  { @include block-spacing($type: 'margin', $place: 'top', $values: -0 -0 -0);  }

.spacing-bottom--12 [class*="hero-block--bottom"] { @include block-spacing($type: 'padding', $place: 'bottom', $values: 4 6 12); }
.spacing-bottom--10 [class*="hero-block--bottom"] { @include block-spacing($type: 'padding', $place: 'bottom', $values: 4 6 10); }
.spacing-bottom--8 [class*="hero-block--bottom"]  { @include block-spacing($type: 'padding', $place: 'bottom', $values: 4 6 8);  }
.spacing-bottom--6 [class*="hero-block--bottom"]  { @include block-spacing($type: 'padding', $place: 'bottom', $values: 4 6 6);  }
.spacing-bottom--4 [class*="hero-block--bottom"]  { @include block-spacing($type: 'padding', $place: 'bottom', $values: 4 4 4);  }
.spacing-bottom--3 [class*="hero-block--bottom"]  { @include block-spacing($type: 'padding', $place: 'bottom', $values: 3 3 3);  }
.spacing-bottom--2 [class*="hero-block--bottom"]  { @include block-spacing($type: 'padding', $place: 'bottom', $values: 2 2 2);  }
.spacing-bottom--0 [class*="hero-block--bottom"]  { @include block-spacing($type: 'padding', $place: 'bottom', $values: 0 0 0);  }

.spacing-bottom--12 [class*="hero-block--bottom"] { @include block-spacing($type: 'marging', $place: 'bottom', $values: -4 -6 -12); }
.spacing-bottom--10 [class*="hero-block--bottom"] { @include block-spacing($type: 'marging', $place: 'bottom', $values: -4 -6 -10); }
.spacing-bottom--8 [class*="hero-block--bottom"]  { @include block-spacing($type: 'marging', $place: 'bottom', $values: -4 -6 -8);  }
.spacing-bottom--6 [class*="hero-block--bottom"]  { @include block-spacing($type: 'marging', $place: 'bottom', $values: -4 -6 -6);  }
.spacing-bottom--4 [class*="hero-block--bottom"]  { @include block-spacing($type: 'marging', $place: 'bottom', $values: -4 -4 -4);  }
.spacing-bottom--3 [class*="hero-block--bottom"]  { @include block-spacing($type: 'marging', $place: 'bottom', $values: -3 -3 -3);  }
.spacing-bottom--2 [class*="hero-block--bottom"]  { @include block-spacing($type: 'marging', $place: 'bottom', $values: -2 -2 -2);  }
.spacing-bottom--0 [class*="hero-block--bottom"]  { @include block-spacing($type: 'marging', $place: 'bottom', $values: -0 -0 -0);  }


.hero-block__content {

  z-index: 1;

  @include when-larger-than('tablet-landscape') {
    background-color: $white;
    box-shadow: 0px 0px 20px 1px rgba(0, 0, 0, 0.1);
  }

  @include when-larger-than('any') { padding-bottom: 2rem; }
  @include when-larger-than('tablet-landscape') { padding: 4rem 4rem; }
  @include when-larger-than('desktop') { padding: 6rem 6rem; }


  .ttl-combo {
    height: 100%;
    display: flex;
    flex-flow: column nowrap;
    .ttl-combo__links {
      margin-top: auto;
      padding-top: 2rem;
      @include when-smaller-than('tablet-landscape') {
        margin-top: 0;
      }
    }
  }

}


.hero-block__image {
  position: relative;
  background-color: $grey-200;
  overflow: hidden;
  img {
    position: absolute;
    object-fit: cover;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
  }
}
