/* Button LINKS */
/* --------------------------------------------------------------------- */

button.btn,
.btn[type='button'],
.btn[type='reset'],
.btn[type='submit'] {
  display: inline-block;
  appearance: none;
  cursor: pointer;
  user-select: none;
  vertical-align: middle;
  white-space: nowrap;
}

a.btn,
button.btn,
.btn[type='button'],
.btn[type='reset'],
.btn[type='submit'] {
  font-family: "Archer A", "Archer B";
  font-weight: 700;
  font-size: #{(14 / 12)}rem;
  text-transform: uppercase;
  letter-spacing: 1.45px;
  line-height: 1rem;
  background-image: none;
  background: none;
  border: none;
  text-shadow: unset;
  display: inline-block;
  padding: 1rem;
  margin-left: -1rem;
  position: relative;
  overflow: hidden;
  &:after {
    position: absolute;
    content: "";
    bottom: 0; left: 1rem;
    width: calc(100% - 2rem);
    height: 1px;
    background-color: $link-decor-color;
    animation: btn-translate-in 250ms 100ms normal ease-in-out backwards;
    z-index: -1;
  }
  &:before {
    position: absolute;
    content: "";
    top: 0; left: 0;
    width: 100%;
    height: 100%;
    background-color: $grey-200;
    animation: btn-translate-out 200ms 0ms normal cubic-bezier(.55,.06,.68,.19) backwards;
    transform: translateX(calc(-100%));
    z-index: -1;
  }
  &:hover {
    &:after {
      animation: btn-translate-out 250ms 0ms normal ease-in-out forwards;
    }
    &:before {
      animation: btn-translate-in 250ms 100ms normal cubic-bezier(.55,.06,.68,.19) forwards;
    }
  }
  &:target, &:active {
    &:after {
      //animation: translate-in 200ms 300ms normal ease-in-out backwards;
    }
    &:before {
      animation: btn-translate-out 400ms 0ms normal cubic-bezier(.55,.06,.68,.19) backwards;
    }
  }
}

[class$="--dark"] {
  a.btn,
  button.btn,
  .btn[type='button'],
  .btn[type='reset'],
  .btn[type='submit'] {
    color: $white;
    text-align: center;
    margin-left: 0;
    &:before {
      background-color: $blue-300;
    }
    &:after {
      width: 100%;
      left: 0rem;
    }
  }
}

/* BTN Link animation keyframes */
/* --------------------------------------------------------------------- */

@keyframes btn-translate-in {
  0%   { transform: translate(calc(-100% - 2rem), 0%) matrix(1, 0, 0, 1, 0, 0); }
  100% { transform: translate(0%, 0%) matrix(1, 0, 0, 1, 0, 0); }
}
@keyframes btn-translate-out {
  0%   { transform: translate(0%, 0%) matrix(1, 0, 0, 1, 0, 0); }
  100% { transform: translate(calc(100% + 2rem), 0%) matrix(1, 0, 0, 1, 0, 0); }
}
