
@mixin card__meta {
  .card__meta-top,
  .card__meta-bottom {
    @include font-style-meta;
    position: relative;
    z-index: 2;
    padding: 0;
    white-space: nowrap;
    overflow: hidden;
    pointer-events: none;
    display: flex;
    justify-content: space-between;
    a {
      pointer-events: all;
    }
  }
  .card__meta__event-date {
    font-weight: 500;
  }
  // i.meta-icon {
  //   height: 14px;
  //   width: 14px;
  //   margin-right: 0.5rem;
  //   display: inline-block;
  //   svg {
  //     fill: $blue-300;
  //     width: 100%;
  //     height: 100%;
  //   }
  // }
}

@mixin card__meta--sm {
  .card__meta-top {
    padding-bottom: 0.5rem;
  }
  .card__meta-bottom {
    margin-top: auto;
    padding-top: 2rem;
  }
}

@mixin card__meta--lg {
  .card__meta-top {
    padding-bottom: 3rem;
  }
  .card__meta-bottom {
    margin-top: auto;
    padding-top: 3rem;
  }
}
