// -----------------------------------------------------------------------------

.block-taxonomy {
  display: grid;
  position: relative;
  grid-template-columns: minmax(5rem, auto) minmax(0, 5rem) repeat(10,  minmax(0, 10rem)) minmax(0, 5rem) minmax(5rem, auto);
  @include when-smaller-than('tablet-portrait') {
    grid-template-columns: 2.5rem 1fr 2.5rem;
  }
  @include block-spacing($type: 'margin', $place: 'bottom', $values: 3 6 12);
}

.block-taxonomy__wrapper {
  background-color: $blue-200;
  display: grid;
  position: relative;
  grid-template-columns: 2.5rem minmax(0, 10rem) 1fr minmax(0, 10rem) 2.5rem;
  @include when-smaller-than('desktop') {
    grid-template-columns: 2.5rem minmax(0, 5rem) 1fr minmax(0, 5rem) 2.5rem;
  }
  @include when-smaller-than('tablet-landscape') {
    grid-template-columns: 2.5rem minmax(0, 0) 1fr minmax(0, 0) 2.5rem;
  }
  @include block-spacing($place: 'both', $type: 'padding', $values: 4 4 8);
  @include when-larger-than('any') {
    grid-column: 2 / -2;
  }
  @include when-smaller-than('tabletportrait') {
    grid-column: 1 / -1;
  }
}
.block-taxonomy__wrapper + .block-taxonomy__wrapper {
  @include block-spacing($place: 'top', $type: 'padding', $values: 0 0 0);
}

.block-taxonomy--space {
  @include block-spacing($type: 'margin', $place: 'both', $values: 2 4 10);
}


.block-taxonomy__content {
  grid-column: 3 / -3;
}

// -----------------------------------------------------------------------------

.block-taxonomy__column-group {
  @include when-larger-than('any')               { columns: 1 auto; }
  @include when-larger-than('tablet-landscape')  { columns: 2 auto; }
  @include when-larger-than('desktop')           { columns: 3 auto; }
  column-gap: 5rem;
  margin-bottom: -1.5rem;
  column-rule: 1px solid rgba($white, 0.1);
  .block-taxonomy__col-element {
    display: inline-block;
    width: 100%;
    border-bottom: 1px solid rgba($white, 0.1);
    margin-bottom: 1.5em;
    padding-bottom: 1.5em;
    @include when-smaller-than('tablet-portrait') {
      margin-bottom: 1em;
      padding-bottom: 1em;
    }
  }
}

// -----------------------------------------------------------------------------

.block-taxonomy__flex-group {
  display: flex;
  position: relative;
  flex-flow: row wrap;
  margin: -5rem;
  @include when-smaller-than('tablet-landscape') {
    margin: 0rem;
  }
  .block-taxonomy__flex-element {
    min-width: 20rem;
    flex: 1;
    padding: 2.5rem;
    margin: 2.5rem;
    @include when-smaller-than('tablet-landscape') {
      padding-top: 0;
      margin: 0rem;
    }
  }
}

// -----------------------------------------------------------------------------

h2.block-taxonomy__title {
  color: $white;
  margin-top: 0;
  margin-bottom: 2rem;
  font-weight: 500;
  font-size: #{(33 / 12)}rem;
  line-height: 3rem;
  display: inline;
  transition: color 200ms ease-in-out;
}

h3.block-taxonomy__title {
  color: $white;
  margin-top: 0;
  margin-bottom: 0;
  font-weight: 500;
  font-size: #{(24 / 12)}rem;
  line-height: 2.5rem;
  display: inline;
  transition: color 200ms ease-in-out;
}

a.block-taxonomy__title-link {

  position: relative;
  display: inline-block;
  width: 100%;
  @include link-reset;

  .block-taxonomy__title {
    @include link-reset;
    position: relative;
    display: inline-block;
  }

  .block-taxonomy__title:before {
    display: inline-block;
    position: absolute;
    content: "Voir tout";
    width: 6.5rem;
    height:100%;
    background-image: none;
    text-shadow: none;
    @include font-style-meta;
    color: $white;
    top: 50%;
    right: -1.5rem;
    transform: translate(0%, -45%);
    opacity: 0;
    transition: all 300ms 0ms ease-in-out;
  }

  .block-taxonomy__title:after {
    display: block;
    position: absolute;
    content: "";
    background-position: 100% 100%;
    background-size: 50%;
    background-repeat: no-repeat;
    transition: all 300ms 200ms ease-in-out;
  }

  h2.block-taxonomy__title::after {
    top: 50%;
    right: -2rem;
    width: 20px;
    height:16px;
    transform: translate(0%, -20%);
    background-image: url("data:image/svg+xml,%3Csvg width='8' height='13' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M.297 11.297L4.89 6.703.297 2.11 1.703.703l6 6-6 6z' fill='%2315C972' fill-rule='evenodd'/%3E%3C/svg%3E");
  }

  h3.block-taxonomy__title::after {
    top: 50%;
    right: -1.5rem;
    width: 16px;
    height:13px;
    transform: translate(0%, -30%);
    background-image: url("data:image/svg+xml,%3Csvg width='8' height='13' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M.297 11.297L4.89 6.703.297 2.11 1.703.703l6 6-6 6z' fill='%2315C972' fill-rule='evenodd'/%3E%3C/svg%3E");
  }

  &:hover {
    .block-taxonomy__title:before {
      transition: all 300ms 200ms ease-in-out;
      transform: translate(10.5rem, -45%);
      right: 3rem;
      opacity: 1;
    }
    .block-taxonomy__title:after {
      transition: all 300ms 0ms ease-in-out;
      transform: translate(11rem, -30%);
      right: 3rem;
    }
  }

}

.block-taxonomy__subtitle {
  @include font-style-meta-title;
  color: $cyan-200;
  margin:0;
  padding-bottom: 1rem;
  line-height: 2rem;
  display: inline-block;
}

a.block-taxonomy__subtitle-link {
  @include link-reset;
  margin: 0;
  padding: 0;

  &:hover .block-taxonomy__subtitle {
    color: $white;
  }
}

.block-taxonomy__title + .block-taxonomy__subtitle,
.block-taxonomy__title + a.block-taxonomy__subtitle-link,
a.block-taxonomy__title-link + .block-taxonomy__subtitle,
a.block-taxonomy__title-link + a.block-taxonomy__subtitle-link {
  padding-top: 1rem;
}


ul.block-taxonomy_link-list {
  list-style: none;
  padding: 0;
  margin: 0;
  li a {
    font-size: #{(18 / 12)}rem;
    line-height: 2.5rem;
    color: $white;
    text-shadow: 2px 0 $blue-200,-2px 0 $blue-200,0 2px $blue-200,0 -2px $blue-200,2px 0 $blue-200,-2px 0 $blue-200,2px 0 $blue-200,-2px 0 $blue-200,2px 0 $blue-200,-2px 0 $blue-200,2px 0 $blue-200,-2px 0 $blue-200;
    background-image: none;
    &:hover {
      background-image: linear-gradient($white,$white),linear-gradient($white,$white),linear-gradient($white,$white);
    }
  }

  ul.chapter_links {
    padding: 1rem 0 2rem;
    li a.chapter_link {
      @include link-reset;
      color: $white;
      background-color: rgba($white, 0.05);
      &:hover, &:focus, &:active {
        background-color: darken($blue-200, 2%);
      }
      display: inline-block;
      margin-left: -1px;
      margin-bottom: 1px;
      padding: 0 3px 3px 3px;
      min-width: 24px;
      height: 24px;
      font-size: 14px;
      text-align: center;
      line-height: 24px;
    }
  }


}
