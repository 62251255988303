@import "normalize";
@import "bourbon";

// General
// --------------------------------
@import "variables";
@import "breakpoints";
@import "general";
@import "grids";
@import "typography";
@import "links";
@import "forms";
@import "themes";

// Components
// --------------------------------
@import "../../node_modules/flickity/dist/flickity";
@import "components/combos";
@import "components/cards";
@import "components/navigation";
@import "components/flickityButtons";

// Blocks
// --------------------------------
@import "blocks/headerBlocks";
@import "blocks/contentBlocks";
@import "blocks/cardHolders";
@import "blocks/taxonomyBlock";
@import "blocks/imageGallery";
@import "blocks/footer";

// Pages and entries
// --------------------------------
@import "pages/simplePage.scss";
@import "canals/entry.scss";
@import "canals/authors.scss";
@import "userflow.scss";
