

.simple-page__wrapper {
  display: grid;
  position: relative;
  @include block-spacing($place: 'both');
  @include when-larger-than('desktop') {
    grid-template-rows: [header-start content-start] 1fr [content-end header-end];
    grid-template-columns: minmax(5rem, auto) [header-start] minmax(0, 30rem) [header-end] minmax(0, 10rem) [separation] minmax(0, 10rem) [content-start] minmax(0, 50rem) [content-end] minmax(5rem, auto);
  }
  @include when-smaller-than('desktop') {
    grid-template-rows: [header-start] auto [header-end content-start] auto [header-start];
    grid-template-columns: minmax(5rem, auto)[header-start content-start] 1fr [content-end header-end] minmax(5rem, auto);
  }
  @include when-smaller-than('tablet-portrait') {
    grid-template-columns: minmax(2.5rem, auto) [header-start content-start] 1fr [content-end header-end] minmax(2.5rem, auto);
  }
}

.simple-page__wrapper:after {
  display: none;
  @include when-larger-than('tablet-landscape') {
    grid-row: 1 / -1;
    grid-column: separation / -1;
    display: block;
    position: relative;
    z-index: 0;
    content: "";
    background-color: $grey-100;
  }
}

.simple-page__header {
  position: relative;
  z-index: 1;
  grid-row: header-start / header-end;
  grid-column: header-start / header-end;
  @include when-larger-than('desktop') { padding: 10rem 0; max-width: 30rem; }
  @include when-smaller-than('desktop') { padding: 0 0 1rem 0; max-width: 50rem; margin-bottom: 3rem; border-bottom: 1px solid $grey-400; }
  &__title-group {
    margin-top: 0;
    @include when-larger-than('desktop') { margin-bottom: 4rem; }
    @include when-smaller-than('desktop') { margin-bottom: 2rem; }
  }
}

.simple-page__sticky-sidebar {
  @include when-larger-than('desktop') {
    position: sticky;
    top: 10rem;
  }
}

.simple-page__content {
  position: relative;
  z-index: 1;
  grid-row: content-start / content-end;
  grid-column: content-start / content-end;
  @include when-larger-than('desktop') { padding: 10rem 0; max-width: 50rem; }
  @include when-smaller-than('desktop') { padding: 0; max-width: 50rem; }
}
