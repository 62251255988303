

// -----------------------------------------------------------------------------
// + Intro
// -----------------------------------------------------------------------------

.card-shuffle__intro {

  @include block-wrapper($cols: 10);
  position: relative;
  z-index: 2;

  .card-shuffle__intro__wrapper {
    grid-column: 2 / -2;
    display: block;
  }

  &.card-shuffle__intro__horizontal-line {
    position: relative;
    padding: 2rem 0 7rem 0;
    &:after {
      position: absolute;
      bottom: -3rem;
      left: 50%;
      content: "";
      display: block;
      width: 1px;
      height: 10rem;
      background-color: darken($grey-400, 5%);
    }
  }

  .card-shuffle__intro__holder {
    @include when-larger-than('tablet-portrait') {
      max-width: 60%;
    }
    @include when-larger-than('tablet-mid') {
      max-width: 40%;
    }
  }
}

// -----------------------------------------------------------------------------
// + Card wrappers
// -----------------------------------------------------------------------------


.card-shuffle__cards {
  display: grid;
  position: relative;
  grid-template-columns: [start] minmax(5rem, auto) [col-start] minmax(0, 100rem) [col-end] minmax(5rem, auto) [end];
  @include when-smaller-than('tablet-portrait') {
    grid-template-columns: [start] 2.5rem [col-start] 1fr [col-end] 2.5rem [end];
  }
  justify-content: center;
  position: relative;
  z-index: 1;
  background-color: $grey-100;
  padding-top: 10rem;
  padding-bottom: 7rem;
  @include when-larger-than('tablet-landscape-plus') {
    padding-bottom: 0;
  }
  &:after {
    @include when-larger-than('tablet-landscape-plus') {
      z-index: 1;
      content: "";
      display: block;
      height: 7.5rem;
      width: 100%;
      bottom: 0;
      left: 0;
      background-color: $white;
      position: absolute;
    }
  }
  .card-shuffle__cards__holder {
    position: relative;
    z-index: 2;
    grid-column: 2 / -2;
    display: flex;
    justify-content: center;
  }
  .card-shuffle__cards__wrapper {
    @include when-larger-than('any') {
      flex: 0 1 40rem;
      display: flex;
      flex-flow: row wrap;
    }
    @include when-larger-than('tablet-mid') {
      margin: -3rem -1.5rem -1.5rem -1.5rem;
      flex: 0 1 83rem;
    }

    @include when-larger-than('desktop') {
      flex: 1 1 100%;
      display: grid;
      grid-template-columns: minmax(0, 10rem) 40rem 40rem minmax(0, 10rem);
      grid-gap: 3rem;
      grid-auto-rows: minmax(14rem, auto);
      grid-auto-flow: row;
    }
  }
}

@include when-between('tablet-mid', 'desktop') {
  .card-shuffle__card:nth-of-type(1) { order: 1; }
  .card-shuffle__card:nth-of-type(2) { order: 2; }
  .card-shuffle__card:nth-of-type(3) { order: 3; }
  .card-shuffle__card:nth-of-type(4) { order: 6; transform: translate(3rem, 0); }
  .card-shuffle__card:nth-of-type(5) { order: 5; }
  .card-shuffle__card:nth-of-type(6) { order: 4; }
  .card-shuffle__card:nth-of-type(7) { order: 7; }
  .card-shuffle__card:nth-of-type(8) { order: 8; }
  .card-shuffle__card:nth-of-type(9) { order: 9; }
}

// -----------------------------------------------------------------------------
// + Spacer card
// -----------------------------------------------------------------------------

.card-shuffle__spacer-card {
  @include when-larger-than('any') {
    display: none;
  }
  @include when-larger-than('desktop') {
    display: block;
    grid-column-end: span 1;
    grid-row-end: span 3;
  }
}

// -----------------------------------------------------------------------------
// + Image card
// -----------------------------------------------------------------------------

.card-shuffle__image-card {
  @include when-larger-than('any') {
    background-color: $grey-300;
    position: relative;
    flex: 0 1 auto;
    width: calc(100% - 3rem);
    margin: 3rem 0 0 3rem;
    padding: 0;
  }
  @include when-larger-than('tablet-mid') {
    flex: 0 1 auto;
    width: calc(50% - 3rem);
    margin: 3rem 1.5rem 3rem 1.5rem;
  }
  @include when-larger-than('desktop') {
    grid-column-end: span 1;
    grid-row-end: span 3;
    margin: 3rem 0 0 3rem;
    width: calc(100% - 3rem);
    height: calc(100% - 3rem);
  }
  &:before {
    z-index: -1;
    display: block;
    position: absolute;
    content: "";
    background-color: $grey-300;
    top: 0; left: 0;
    width: 100%;
    height: 100%;
    transform: translate(-3rem, -3rem);
  }

  &--grey-100:before { background-color: $grey-100; }
  &--blue-200:before { background-color: $blue-200; }
  &--green-200:before { background-color: $green-200; }
  &--yellow-200:before { background-color: $yellow-200; }
  &--cyan-200:before { background-color: $cyan-200; }
  &--orange-200:before { background-color: $orange-200; }

  .card-shuffle__image-card__holder {
    @include when-larger-than('any') {
      position: relative;
      overflow:hidden;
      display: block;
      padding-bottom: 120%;
      height: 0;
      width: 100%;
    }
    @include when-larger-than('desktop') {
      position: relative;
      overflow:hidden;
      padding-bottom: 0;
      height: 100%;
    }
    img {
      position: absolute;
      display: block;
      top: 0; left: 0;
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }

}

// -----------------------------------------------------------------------------
// + Content card
// -----------------------------------------------------------------------------

// + Card
// --------------------
.card-shuffle__content-card {
  @include when-larger-than('any') {
    position: relative;
    flex: 0 1 auto;
    width: 100%;
    margin: 3rem 0 6rem 0;
    padding: 0;
  }
  @include when-larger-than('tablet-mid') {
    position: relative;
    flex: 0 1 auto;
    width: calc(50% - 3rem);
    margin: 3rem 1.5rem 3rem 1.5rem;
    padding: 0;
  }
  @include when-larger-than('tablet-landscape-plus') {
    background-color: $white;
    box-shadow: 0px 0px 20px 1px rgba(0, 0, 0, 0.1);
    margin: 3rem 1.5rem 1.5rem 1.5rem;
  }
  @include when-larger-than('desktop') {
    grid-column-end: span 2;
    grid-row-end: span 2;
    margin: 0;
    width: 100%;
    height: 100%;
  }
}

// + Holder
// --------------------
.card-shuffle__content-card__holder {
  @include when-larger-than('tablet-mid') {
    position: relative;
    padding-bottom: 120%;
    height: 0;
  }
  @include when-larger-than('desktop') {
    position: relative;
    padding-bottom: 0;
    height: 100%;
  }
}

// + TTL ttl-combo
// --------------------
.card-shuffle__content-card__holder > .ttl-combo {
  width: 100%;
  display: flex;
  flex-flow: column nowrap;
  @include when-larger-than('tablet-portrait') {
    padding-top: 3rem;
  }
  @include when-larger-than('tablet-landscape-plus') {
    position: absolute;
    top: 0; left: 0;
    padding: 4rem;
    height: 100%;
  }
  @include when-larger-than('desktop') {
    padding: 5rem;
  }
  @include when-larger-than('any') {
    .ttl-combo__links { margin-top: 2rem;}
  }
  @include when-larger-than('tablet-landscape-plus') {
    .ttl-combo__title-group { margin-bottom: auto;}
    .ttl-combo__links { margin-top: auto;}
  }
}
