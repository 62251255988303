

.block-footer {
  display: grid;
  position: relative;
  @include block-wrapper($cols: 10);
  @include block-spacing($place: 'top');
  &:after {
    z-index: 1;
    grid-column: 1 / -1;
    content: "";
    pointer-events: none;
    display: block;
    position: relative;
    width: 100%;
    height:0;
    padding-bottom: 35%;
    bottom: 0; left: 0;
    background-image: url("../img/grenoble_skyline_outline.png");
    background-size: 100%;
    background-position: bottom;
    background-repeat: no-repeat;
  }
}

.block-footer__content {
  position: relative;
  z-index: 2;
  grid-column: 2 / -2;
  @include when-smaller-than('tablet-portrait') {
    br { display: none; }
  }
}

.block-footer__cta__wrapper {
  @include block-spacing($place: 'bottom', $type: 'padding', $values: 4 4 4 );
}

.block-footer__link-list-grid {
  display: flex;
  flex-flow: row wrap;
  margin: -2.5rem;
  @include block-spacing($place: 'both', $type: 'padding', $values: 3 3 3 );
}

.block-footer__link-list {
  padding: 2.5rem;
  width: 20rem;
  @include when-smaller-than('tablet-portrait') {
    width: 50%;
    min-width: 20rem;
  }
  h3 {
    font-family: "Whitney A", "Whitney B";
    font-weight: 600;
    font-size: 1.458rem; // 17.5px
    margin-top: 0;
    padding-bottom: 0;
  }
  ul {
    list-style: none;
    padding: 0;
    margin: 1rem 0;
  }
  li {
    font-size: 1.333rem; // 16px
  }
  li a {
    text-shadow: none;
    background-image: linear-gradient($grey-100, $grey-100),linear-gradient($grey-100, $grey-100),linear-gradient(transparent, transparent);
    &:hover {
      background-image: linear-gradient($grey-100, $grey-100),linear-gradient($grey-100, $grey-100),linear-gradient($blue-200, $blue-200);
    }
  }
}

.block-legal {
  display: grid;
  position: relative;
  @include block-wrapper($cols: 10);
  @include block-spacing($place: 'both', $type: 'padding', $values: 2 2 2);
  background-color: $blue-200;
}

.block-legal__content {
  position: relative;
  z-index: 2;
  grid-column: 2 / -2;
  color: rgba($white, 0.5);
  display: flex;
  flex-flow : row wrap;
  justify-content: space-between;
  a {
    color: rgba($white, 0.5);
    &:hover, &:focus, &:active {
      color: rgba($white, 0.5);
    }
  }
}
