
// -----------------------------------------------------------------------------
// + Default header
// -----------------------------------------------------------------------------

.default-header {
  @include block-wrapper($cols: 10);
  @include block-spacing($place: 'both');
  min-height: 0;
  min-width: 0;
  z-index: 1;
}

.default-header__content {

  position: relative;

  @include when-larger-than('any')               { grid-column: 2 / -2; padding: 0 0rem 5rem 0; }
  @include when-larger-than('tablet-portrait')   { grid-column: 2 / -2; padding: 0 0rem 5rem 0; }
  @include when-larger-than('tablet-landscape')  { grid-column: 2 / 8;  padding: 0 2.5rem 5rem 0; }
  @include when-larger-than('desktop')           { grid-column: 2 / 7;  padding: 0 2.5rem 5rem 0; }

  .default-header__nav {
    margin-bottom: 6rem;
    @include hide-visually("hide");
    @include when-larger-than('tablet-portrait') {
      @include hide-visually("unhide");
    }
  }

  .default-header__title-group {
    @include block-spacing('margin', 'top', 2 4 6);
    @include block-spacing('margin', 'bottom', 2 4 4);
    @include when-smaller-than('tablet-landscape') {
      br { display: none; }
    }
    .default-header__title { color: $blue-200;}
    .default-header__subtitle { color: $blue-100;}
  }

  .default-header__intro {
    @include when-larger-than('tablet-mid') {
      padding-right: 5rem;
    }
    @include when-larger-than('desktop') {
      a.btn, a.cta {
        margin-top: 10rem;
      }
    }
  }

}

.default-header__image {

  position: relative;
  width: 100%;
  min-height: calc(100vh - 20rem);
  margin-bottom: 5rem;

  @include when-larger-than('any')               { grid-column: 1 / -1; }
  @include when-larger-than('tablet-portrait')   { grid-column: 2 / -2; }
  @include when-larger-than('tablet-landscape')  { grid-column: 9 / -1; }
  @include when-larger-than('desktop')           { grid-column: 8 / -1; }

  img {
    position: absolute;
    top: 0; left: 0;
    display: block;
    object-fit: cover;
    width: 100%;
    height: 100%;
  }

}

.default-header:after {

  display: none;
  content: "";
  width: 100%;
  background-color: $grey-100;
  z-index: -1;

  position: absolute;
  width: 100%;
  min-height: calc(100vh - 20rem);
  margin: 5rem 0 0 5rem;

  @include when-larger-than('tablet-landscape')  { display:block; grid-column: 8 / -1; }
  @include when-larger-than('desktop')           { display:block; grid-column: 7 / -1; }

}
