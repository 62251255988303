
// -----------------------------------------------------------------------------
// Dropdown select used on entryNav.scss
// -----------------------------------------------------------------------------

.nav__button {

  position: relative;
  display: flex;
  background-image: none;
  text-shadow: none;

  .nav__button__label {
    display: block;
    position: relative;
    height: 4rem;
    line-height: 3.8rem;
    padding: 0rem 1.5rem;
    margin-bottom: 0!important;
    border: 1px solid darken($grey-400, 5%);
    background-color: $white;
    color: $blue-300;
    font-family: "Whitney SC A", "Whitney SC B";
    text-transform: lowercase;
    font-size: #{(18 / 12)}rem;
    font-weight: 400;
  }

  .nav__button__link {
    display: block;
    background-image: none;
    text-shadow: none;
    cursor: pointer;
    outline: none;
    appearance: none;
    @include font-style-meta;
    line-height: 4rem;
    font-weight: 400;
    height: 4rem;
    padding: 0rem 1rem;
    margin-bottom: 0!important;
    background-color: $grey-100;
    color: $blue-300;
    border: 1px solid darken($grey-400, 5%);
    border-radius: 0;
    &:hover {
      background-color: $grey-200;
      transition: background-color 150ms ease-in;
    }
    &:focus {
      color: $blue-300;
    }
  }

  .nav__button__label + .nav__button__link { margin-left: -1px; }
  .nav__button__link + .nav__button__label { margin-left: -1px; }

  .nav__button__link--has-icon-left { padding: 0rem 3rem 0.25rem 1rem; }
  .nav__button__link--has-icon-right { padding: 0rem 1rem 0.25rem 3rem; }

  &:hover i.nav__button__icon svg {
    fill: #c6000e;
  }

}

div.nav__button--compact {
  span.nav__button__label {
    display: none;
  }
  a.nav__button__link {
    border: 1px solid darken($grey-400, 5%);
    background-color: $white;
    color: $blue-300;
  }
}

i.nav__button__icon {
  position: absolute;
  pointer-events: none;
  width: 1.5rem;
  height:1.5rem;
  top: 1.25rem;
  right: 1.25rem;
  svg {
    fill: $blue-300;
    width: 100%;
    height: 100%;
  }
}

i.nav__button__icon--bigger {
  width: 2rem;
  height: 2rem;
  top: 1.1rem;
  right: 1rem;
}

i.nav__button__label-icon {
  pointer-events: none;
  display: inline-block;
  position: relative;
  width: 1.5rem;
  height:1.5rem;
  margin: 1.25rem 0;
  svg {
    position: absolute;
    fill: $blue-300;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
  }
}
