

// -----------------------------------------------------------------------------
// + Content block holder
// -----------------------------------------------------------------------------

.content-block {
  display:block;
  position: relative;
}

.content-block .block-card-rack {
  margin: 0;
}

// -----------------------------------------------------------------------------
// + Content block styles
// -----------------------------------------------------------------------------

@import "contentBlocks/introCombo";
@import "contentBlocks/contentCombo";
@import "contentBlocks/autoCols";
@import "contentBlocks/preSetCols";
@import "contentBlocks/peopleGrid";
@import "contentBlocks/heroBlock";
@import "contentBlocks/shuffleCards";

// -----------------------------------------------------------------------------
// + Content rulers (not implemented yet)
// -----------------------------------------------------------------------------

.content-block--ruler-before {
  position: relative;
  &:before {
    grid-column: -2 / 2;
    content: "";
    display: block;
    z-index: 10;
    position: absolute;
    width: 100%;
    height: 2px;
    background-color: $white;
    border-bottom: 1px solid $grey-300;
    top: 0;
  }
}

.content-block--ruler-after {
  position: relative;
  &:after {
    grid-column: -2 / 2;
    content: "";
    display: block;
    z-index: 10;
    position: absolute;
    width: 100%;
    height: 2px;
    background-color: $white;
    border-bottom: 1px solid $grey-300;
    bottom: 0;
  }
}
