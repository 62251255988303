
$breakpoints: (
  'any' : 0px,
  'tablet-portrait' : 600px,
  'tablet-mid' : 780px,
  'tablet-landscape' : 900px,
  'tablet-landscape-plus' : 960px,
  'desktop' : 1200px,
  'big-desktop' : 1600px
);


$breakpoints_minus_one: (
  'any' : 0px,
  'tablet-portrait' : 599px,
  'tablet-mid' : 779px,
  'tablet-landscape' : 899px,
  'tablet-landscape-plus' : 959px,
  'desktop' : 1199px,
  'big-desktop' : 1599px
);

@mixin when-smaller-than($s) {
  @if map-has-key($breakpoints_minus_one, $s) {
    $breakpoint : map-get($breakpoints_minus_one, $s);
    @media (max-width: $breakpoint) { @content; }
  } @else {
    @media (max-width: $s) { @content; }
  }
}

@mixin when-larger-than($s) {
  @if map-has-key($breakpoints, $s) {
    $breakpoint : map-get($breakpoints, $s);
    @media (min-width: $breakpoint) { @content; }
  } @else {
    @media (min-width: $s) { @content; }
  }
}

@mixin when-between($low, $up) {
  @if map-has-key($breakpoints, $low) and map-has-key($breakpoints, $up) {
    $lower_breakpoint : map-get($breakpoints, $low);
    $upper_breakpoint : map-get($breakpoints, $up);
    @media (min-width: $lower_breakpoint) and (max-width: $upper_breakpoint) { @content; }
  } @else {
    @media (min-width: $low) and (max-width: $up) { @content; }
  }
}


@mixin for-phone-only {
  @media (max-width: 599px) { @content; }
}
@mixin for-all {
  @content;
}
.hide-on-mobile {
  @include when-smaller-than('tablet-portrait') {
    @include hide-visually("hide");
  }
}
