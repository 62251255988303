
@mixin card__title-group {

  .card__title-group {

    position: static;
    display: block;
    background-image: none;
    background-color: transparent;
    text-shadow: unset;
    overflow: hidden;
    z-index: 1;

    &:before {
      display: none;
    }

    &:after {
      display: block;
      content: "";
      position: absolute;
      top: 0; left: 0;
      height: 100%;
      width: 100%;
    }

    .card__title-wrapper {
      display:block;
      position: relative;
      height: 100%;;
      &:after {
        z-index: 2;
        display: block;
        position: absolute;
        content: "";
        background-image: linear-gradient(
          90deg,
          hsla(0,0%,100%,0) 0%,
          hsla(0,0%,100%,0.6) 30%,
          hsla(0,0%,100%, 0.7) 40%,
          hsla(0,0%,100%, 1) 60%,
          hsl(0,0%,100%) 100%
        );
      }

      &:before {
        z-index: 2;
        display: block;
        position: absolute;
        content: "";
        background-color: $white;
      }
    }

    .card__title-text {
      @include link-style-base;
      @include link-style--no-line;
      display: inline;
      z-index: 1;
      margin: 0;
      padding: 0;
      font-family: "Whitney A", "Whitney B";
    }

    .card__title {
      font-weight: 600;
      color: $blue-300;
    }

    .card__subtitle {
      font-weight: 400;
      color: $blue-100;
    }

  }

  &:hover {
    .card__title-text {
      @include link-hover--green-line;
    }
  }

}


// + -- Large

@mixin card__title-group--lg {
  .card__title-group { height: auto; }
  .card__title-wrapper {
    &:before { display: none; }
    &:after { display: none; }
  }
  .card__title-text {
    .card__title,
    .card__subtitle {
      font-size: #{(33 / 12)}rem!important;
      line-height: 3.6rem;
    }
    .card__title::after {
      // content: "\A"; // force a line-break
      // white-space: pre;
    }
  }
}

// + -- Medium

@mixin card__title-group--md {
  .card__title-group { height: 8rem; }
  .card__title-wrapper {
    &:before {
      width: 100%; height: 2rem;
      bottom: -1.7rem; right: 0;
    }
    &:after {
      width: 35%; height: 3rem;
      bottom: 0; right: 0;
    }
  }
  .card__title-text {
    .card__title,
    .card__subtitle {
      font-size: #{(33 / 12)}rem!important;
      line-height: 3rem;
    }
  }
}

// + -- Small

@mixin card__title-group--sm {
  .card__title-group { height: 8rem; }
  .card__title-wrapper {
    &:before {
      width: 100%; height: 2rem;
      bottom: -1.7rem; right: 0;
    }
    &:after {
      width: 35%; height: 2.5rem;
      bottom: 0; right: 0;
    }
  }
  .card__title-text {
    .card__title,
    .card__subtitle {
      font-size: #{(24 / 12)}rem!important;
      line-height: 2.4rem;
    }
  }
}
