// -----------------------------------------------------------------------------
// + Home header
// -----------------------------------------------------------------------------

.home-header {
  @include block-wrapper($cols: 10);
  @include block-spacing($place: 'top', $type: 'margin', $values: 0 0 -14 );
  @include block-spacing($place: 'bottom', $type: 'margin', $values: 4 6 12 );
  min-height: 0;
  min-width: 0;
  z-index: 1;
}

.home-header__content{

  @include block-spacing($place: 'top', $type: 'margin', $values: 0 0 14 );
  @include block-spacing($place: 'top', $type: 'padding', $values: 4 4 6 );
  @include block-spacing($place: 'bottom', $type: 'padding', $values: 4 4 0 );

  @include when-larger-than('any')               { grid-column: 2 / -2; }
  @include when-larger-than('tablet-portrait')   { grid-column: 2 / -2; }
  @include when-larger-than('tablet-landscape')  { grid-column: 2 / 7;  }
  @include when-larger-than('desktop')           { grid-column: 2 / 6;  }

  display: flex;
  flex-flow: column nowrap;
  max-height: 45rem;
  a.cta {
    margin-top: auto;
    align-self: flex-start;
    @include when-smaller-than('tablet-landscape') {
      margin-top: 2rem;
    }
  }

  @include when-smaller-than('tablet-landscape') {
    h1 br { display: none; }
  }
}


.home-header__image {

  position: relative;
  width: 100%;
  min-height: calc(100vh - 10rem);
  margin-bottom: 5rem;

  @include when-larger-than('any')               { grid-column: 1 / -1; }
  @include when-larger-than('tablet-portrait')   { grid-column: 2 / -2; }
  @include when-larger-than('tablet-landscape')  { grid-column: 9 / -1; }
  @include when-larger-than('desktop')           { grid-column: 8 / -1; }

  img {
    position: absolute;
    top: 0; left: 0;
    display: block;
    object-fit: cover;
    width: 100%;
    height: 100%;
  }

}

.home-header:after {

  display: none;
  content: "";
  width: 100%;
  background-color: $grey-100;
  z-index: -1;

  position: absolute;
  width: 100%;
  min-height: calc(100vh - 15rem);
  margin: 15rem 0 0 0;

  @include when-larger-than('tablet-landscape')  { display:block; grid-column: 8 / -1; }
  @include when-larger-than('desktop')           { display:block; grid-column: 7 / -1; }

}
