
// -----------------------------------------------------------------------------
// + Entry header
// -----------------------------------------------------------------------------

.entry-header {

  position: relative;
  z-index: 1;
  @include block-wrapper($cols: 10);
  grid-template-rows: [start] auto auto [end];
  @include block-spacing($type: 'margin', $place: 'top', $values: 4 4 6);
  @include block-spacing($type: 'margin', $place: 'bottom', $values: 1 2 3);

  .entry-header__content {
    position: relative;
    display: flex;
    flex-direction: column;
    flex: 1;
    justify-content: end;
    background-color: $white;
    z-index: 2;
    padding: 0;
    @include when-larger-than('any') {
      grid-row: 1 / 2;
      grid-column: 2 / -2;
    }
    @include when-larger-than('tablet-landscape') {
      grid-row: 2 / 3;
    }
    //@include when-larger-than('tablet-portrait')   { grid-column: 2 / 11; padding-right: percentage(1/9); }
    //@include when-larger-than('tablet-mid')        { grid-column: 2 / 10; padding-right: percentage(1/8); }
    @include when-larger-than('tablet-landscape')  { grid-column: 2 / 9;  padding-right: percentage(1/7); }
    @include when-larger-than('desktop')           { grid-column: 2 / 8;  padding-right: percentage(1/6); }
  }

  .entry-header__title {
    margin-top: 4rem;
    margin-bottom: 0;
  }

  .entry-header__date {
    @include font-style-date;
    margin-top: 2rem;
    padding-top: 1rem;
    & + .entry-header__title {
      margin-top: 0;
      padding-top: 1rem;
    }
  }
  .entry-header__intro-meta {
    @include font-style-meta;
    border-bottom: 1px solid darken($grey-400, 5%);
    margin-top: 2rem;
    padding-bottom: 2rem;
    display: flex;
    justify-content: space-between;
  }

  .entry-header__image {
    position: relative;
    z-index: 1;
    width: 100%;
    max-width: 100%;
    padding-bottom: 50%;

    @include when-larger-than('any') {
      grid-row: 2 / 3;
      grid-column: 1 / -1;
    }
    @include when-larger-than('tablet-landscape') {
      grid-row: 1 / 3;
      grid-column: 3 / -1;
    }
    @include when-larger-than('desktop') {
      grid-row: 1 / 3;
      grid-column: 3 / -2;
    }
    img {
      position: absolute;
      width: 100%;
      height: 100%;
      top: 0; left: 0;
      overflow: hidden;
    }
    &:before {
      @include when-larger-than('tablet-landscape') {
        display:block;
        position: absolute;
        width: 100%;
        height: 20rem;
        content: "";
        z-index: -2;
        background-color: $grey-200;
        transform: translate(-6rem, -6rem);
      }
    }
  }

}

// -----------------------------------------------------------------------------
// + Entry body
// -----------------------------------------------------------------------------

.entry-body {
  position: relative;
  @include block-wrapper($cols: 10);

  .entry-body__content,
  .entry-body__footer-meta-links,
  .entry-body__footer-meta-tags {
    @include when-larger-than('any')               { grid-column: col-start / col-end; }
    @include when-larger-than('tablet-portrait')   { grid-column: 2 / 10; }
    @include when-larger-than('tablet-mid')        { grid-column: 2 / 9; }
    @include when-larger-than('tablet-landscape')  { grid-column: 2 / 8; }
    @include when-larger-than('desktop')           { grid-column: 2 / 7; }
  }

  .entry-body__footer-meta-links {
  }
  .entry-body__footer-meta-tags {
    border-top: 1px solid $grey-400;
    padding-top: 1.5rem;
    margin-top: 1.5rem;
  }

  .entry-body__footnote {
    padding: 1rem 0;
    p {
      font-style: italic;
      color: $blue-100;
    }
  }
}

.entry-body_matrix-block {
  margin: 3rem 0;
}

.entry-body_paragraph {
  margin: 0;
  font-size: #{(18 / 12)}rem;
}

.entry-body_citation {

}

.entry-body_image {
  img {
    max-width: 100%;
    height:auto;
  }
}

.entry-body_link {

}

.entry-body_video {
  position: relative;
  padding-bottom: 56.25%; /* 16:9 */
  padding-top: 25px;
  height: 0;
  iframe {
  	position: absolute;
  	top: 0;
  	left: 0;
  	width: 100%;
  	height: 100%;
  }
}

.entry-body__audio-player {
  border-bottom: 1px solid darken($grey-400, 5%);
  padding-bottom: 3rem;
  margin-bottom: 2rem;
  @include when-smaller-than('tablet-landscape') {
    border-top: 1px solid darken($grey-400, 5%);
    padding-top: 3rem;
    //margin-top: 0rem;
  }
}

.entry-body__map {
  position:relative;
  padding: 2rem 0;
  .entry-body__adresse {
    padding-bottom:  2rem;
  }
  #googlemaps_canvas {
    position: relative;
    z-index: 1;
  }
}
