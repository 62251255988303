@mixin card__content-group {
  .card__content-group {
    display: flex;
    flex-direction: column;
    justify-content: start;
    flex-wrap: nowrap;
    align-self: stretch;
    padding: 2rem;
  }
}
