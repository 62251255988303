a.cta,
button.cta,
.cta[type='button'],
.cta[type='reset'],
.cta[type='submit'] {
  font-family: "Whitney A", "Whitney B";
  font-weight: 600;
  font-size: #{(18 / 12)}rem;
  letter-spacing: 0px;
  margin-top: 0;
  line-height: 2rem;
  background-image: none;
  background: none;
  border: none;
  text-shadow: unset;
  display: inline-block;
  padding-left: 1.5rem;
  padding-right: 2.5rem;
  position: relative;
  overflow: hidden;
  transition: all 300ms ease-in-out;
  background-color: transparent;
  outline: none;
  cursor: pointer;
  &:after {
    position: absolute;
    z-index: 1;
    content: "";
    width:  #{(14 / 12)}rem;
    height: #{(24 / 12)}rem;
    background-image: url("data:image/svg+xml,%3Csvg width='14' height='20' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M.297 11.297L4.89 6.703.297 2.11 1.703.703l6 6-6 6z' fill='%2315C972' fill-rule='evenodd'/%3E%3C/svg%3E");
    background-position: 0% 50%;
    background-repeat: no-repeat;
    background-size: 120%;
    top: #{(5 / 12)}rem;
    left: #{(2 / 12)}rem;
    animation:
      cta-arrow-translate-out 400ms 200ms normal ease-in-out backwards,
      cta-arrow-left-out 0ms 400ms normal linear backwards;
  }
  &:before {
    position: absolute;
    content: "";
    top: 0; left: 0;
    width: calc(100% + 1.5rem);
    height: 100%;
    background-color: rgba(0,0,0,0.04);
    transform: translateX(-100%);
    z-index: -1;
    animation: cta-box-translate-out 300ms 300ms normal cubic-bezier(.55,.06,.68,.19) backwards;
  }
  &:hover {
    transform: translateX(#{(-18 / 12)}rem);
    &:after {
      animation:
        cta-arrow-translate-in 550ms 250ms normal ease-in-out forwards,
        cta-arrow-left-in 1ms 550ms normal linear forwards;
    }
    &:before {
      animation:
        cta-box-translate-in 350ms 250ms normal cubic-bezier(.55,.06,.68,.19) forwards;
    }
  }
  &:focus {
    outline:0;
  }
  &:target, &:active {
  }
}

[class$="--dark"] {
  a.cta,
  button.cta,
  .cta[type='button'],
  .cta[type='reset'],
  .cta[type='submit'] {
    color: $white;
    text-align: center;
    margin-left: 0;
  }
}

/* --------------------------------------------------------------------- */

a.form-submit,
button.form-submit,
.form-submit[type='button'],
.form-submit[type='reset'],
.form-submit[type='submit']{
  font-weight: 500;
  background-image: none;
  background: none;
  border: none;
  text-shadow: unset;
  background-color: $green-200;
  color: $blue-200;
  padding: 1rem 2rem;
  border-radius: 4px;
  cursor: pointer;
  border: none;
  transition: all 300ms ease-in-out;
  &:hover, &:active, &:focus {
    background-color: lighten($green-200, 5%);
    box-shadow: 1px 2px 6px rgba($blue-200, 0.25);
  }
  &:focus {
    outline:0;
  }
  &.form-submit--grey {
    background-color: $grey-200;
  }
}

/* CTA Link animation keyframes */
/* --------------------------------------------------------------------- */

@keyframes cta-box-translate-in {
  0%   { transform: translate(-100%, 0%) matrix(1, 0, 0, 1, 0, 0); }
  100% { transform: translate(100%, 0%) matrix(1, 0, 0, 1, 0, 0); }
}
@keyframes cta-box-translate-out {
  0%   { transform: translate(calc(0% + #{(8 / 12)}rem;), 0%) matrix(1, 0, 0, 1, 0, 0); }
  100% { transform: translate(-100%, 0%) matrix(1, 0, 0, 1, 0, 0); }
}

@keyframes cta-arrow-translate-in {
  0%   {
    transform: translate(0%, 0%) matrix(1, 0, 0, 1, 0, 0);
    opacity: 100%;
  }
  33%  {
    transform: translate(-100%, 0%) matrix(1, 0, 0, 1, 0, 0);
    opacity: 0%;
  }
  34%  {
    transform: translate(100%, 0%) matrix(1, 0, 0, 1, 0, 0);
    opacity: 0%;
  }
  66%  {
    transform: translate(100%, 0%) matrix(1, 0, 0, 1, 0, 0);
    opacity: 0%;
  }
  100% {
    transform: translate(0%, 0%) matrix(1, 0, 0, 1, 0, 0);
    opacity: 100%;
  }
}

@keyframes cta-arrow-translate-out {
  0%   {
    transform: translate(0%, 0%) matrix(1, 0, 0, 1, 0, 0);
    opacity: 100%;
  }
  33%  {
    transform: translate(100%, 0%) matrix(1, 0, 0, 1, 0, 0);
    opacity: 0%;
  }
  34%  {
    transform: translate(-100%, 0%) matrix(1, 0, 0, 1, 0, 0);
    opacity: 0%;
  }
  66%  {
    transform: translate(-100%, 0%) matrix(1, 0, 0, 1, 0, 0);
    opacity: 0%;
  }
  100% {
    transform: translate(0%, 0%) matrix(1, 0, 0, 1, 0, 0);
    opacity: 100%;
  }
}

@keyframes cta-arrow-left-in {
  0%   { left: #{(-8 / 12)}rem;;}
  33%  { left: #{(-8 / 12)}rem;;}
  34%  { left: calc(100% - 2rem);}
  100% { left: calc(100% - 2rem);}
}

@keyframes cta-arrow-left-out {
  0%   { left: calc(100% - 2rem);}
  33%  { left: calc(100% - 2rem);}
  34%  { left: #{(-8 / 12)}rem;;}
  100% { left: #{(-8 / 12)}rem;;}
}
