

// -----------------------------------------------------------------------------
// + Components - Image Gallery
// -----------------------------------------------------------------------------

.block-image-gallery {
  @include block-wrapper($cols: 10);
}

.block-image-gallery--deco::before {
  @include when-larger-than('tablet-mid') {
    content: '';
    display: block;
    position: absolute;
    top: 0; left: 0;
    width: 100%;
    height: 100%;
    background-color: $grey-100;
  }
  @include when-larger-than('tablet-mid')         { grid-column: 8 / end; }
  @include when-larger-than('tablet-landscape')   { grid-column: 7 / end; }
  @include when-larger-than('desktop')            { grid-column: 6 / end; }
}

.block-image-gallery__UIWrap {
  grid-column: start / end;
  z-index: 1;
}

.block-image-gallery__items-wrap {
  @include block-wrapper;
  @include block-spacing($type: 'padding', $place: 'both', $values: 2 6 10);
}


// --------------------------------------------------------------
// + grid items are card, their columns are defined in cards.scss
// --------------------------------------------------------------

.block-image-gallery__images {
  grid-column: col-start / col-end;
  display: grid;
  overflow: visible;
  grid-auto-flow: row;
  grid-template-columns: 2fr;
  overflow: hidden; // this is to hide the lines bewteen the cards
  grid-gap: 3.5rem;
  @include when-smaller-than('tablet-portrait') {
    .card {
      padding-bottom: 1.5rem;
      border-bottom: 1px solid $grey-300;
    }
  }
  @include when-larger-than('tablet-portrait') {
    grid-gap: 3rem;
    grid-template-columns: repeat(4, 1fr);
  }
  @include when-larger-than('tablet-mid') {
    grid-template-columns: repeat(6, 1fr);
    grid-gap: 5rem;
  }
}

.block-image-gallery__image-card {
  grid-column: span 2;
  .block-image-gallery__image-holder {
    height: 0;
    padding-bottom: percentage(432/360);
    position: relative;
  }
  img {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }
  p {
    @include when-larger-than('tablet-landscape') {
      margin-right: 5rem;
    }
  }
}

// -----------------------------------------------------------------------------
// + Flickity style overrides
// -----------------------------------------------------------------------------

.block-image-gallery {
  .flickity-UI__btnLeft,
  .flickity-UI__btnRight {
    top: calc(50% - 8rem);
  }
}

.flickity-viewport {
  position: static;
}

.flickInit .block-image-gallery__images {
  overflow: visible;
}
.flickInit .block-image-gallery__images .block-image-gallery__image-card {
  width: 100%;
  max-width: 30rem;
  margin-right: 5rem;
  @include when-smaller-than('tablet-portrait') {
    margin-right: 3rem;
  }
}
