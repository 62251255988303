// -----------------------------------------------------------------------------
// + Two Columns Simple Content Block
// -----------------------------------------------------------------------------

.auto-cols {
  @include block-wrapper($cols: 10);
}

.auto-cols__wrapper {
  grid-column: 2 / -2;
  display: block;
}

.auto-cols__holder {

  column-gap: 5rem;
  width: 100%;
  max-width: 100%;
  column-count: 1;

  @include when-larger-than('tablet-landscape') {

    &.auto-cols--align-left   { margin: 0 auto 0 0; }
    &.auto-cols--align-center { margin: 0 auto 0 auto; }
    &.auto-cols--align-right  { margin: 0 0 0 auto; }

    &.auto-cols--width-100 { max-width: 100.0%; }
    &.auto-cols--width-75  { max-width: 75.00%; }
    &.auto-cols--width-66  { max-width: 66.66%; }
    &.auto-cols--width-50  { max-width: 50.00%; }
    &.auto-cols--width-33  { max-width: 33.33%; }
    &.auto-cols--width-25  { max-width: 25.00%; }

  }

  @include when-larger-than('tablet-mid') {

    &.auto-cols--count-1 { column-count: 1; }
    &.auto-cols--count-2 { column-count: 1; }
    &.auto-cols--count-3 { column-count: 2; }
    &.auto-cols--count-4 { column-count: 2; }

  }

  @include when-larger-than('tablet-landscape') {

    &.auto-cols--count-1 { column-count: 1; }
    &.auto-cols--count-2 { column-count: 2; }
    &.auto-cols--count-3 { column-count: 3; }
    &.auto-cols--count-4 { column-count: 4; }

  }

}
