@mixin link-reset {
  &, &:hover, &:focus,
  & > *, & > *:hover, & > *:focus, {
    background-image: none;
    text-shadow: unset;
    outline: inherit;
  }
}

@mixin link-style-base {
  color: $text-base-color;
  text-decoration-skip: ink;
  text-decoration: none;
  transition: color ease 200ms;
  background-position: 0 100%,110% 90%,0 100%;
  background-repeat: no-repeat,no-repeat,repeat-x;
  background-size: 2px 1px,2px 1px,1px 1px;
}

@mixin link-style--green-line {
  text-shadow: 2px 0 $white,-2px 0 $white,0 2px $white,0 -2px $white,2px 0 $white,-2px 0 $white,2px 0 $white,-2px 0 $white,2px 0 $white,-2px 0 $white,2px 0 $white,-2px 0 $white;
  background-image: linear-gradient($white,$white),linear-gradient($white,$white),linear-gradient($link-decor-color,$link-decor-color);
}

@mixin link-style--no-line {
  text-shadow: 2px 0 $white,-2px 0 $white,0 2px $white,0 -2px $white,2px 0 $white,-2px 0 $white,2px 0 $white,-2px 0 $white,2px 0 $white,-2px 0 $white,2px 0 $white,-2px 0 $white;
  background-image: none;
}

@mixin link-hover--green-line {
  color: $link-hover-color;
  background-image: linear-gradient($white,$white),linear-gradient($white,$white),linear-gradient($green-200,$green-200);
}

@mixin link-focus {
  outline: 3px transparentize($link-hover-color, 0.4);
  outline-offset: 2px;
}

@mixin link-style-slide-up {
  @include link-style-base;
  position: relative;
  overflow: hidden;
  display: inline-block;
  vertical-align: bottom;
  white-space: nowrap;
  margin: 0; padding: 0;
  transition: all 200ms;
  &:before {
    position: absolute;
    top: 0;
    left: 0;
    z-index: -1;
    width: 100%;
    height: 100%;
    background-color: $green-100;
    content: '';
    transition: all 100ms;
    transform: translateY(100%);
    opacity: 0;
  }
  &:hover {
    transition: all 100ms;
    text-shadow: unset;
    background-image: none;
    &:before {
      transition: all 200ms;
      transform: translateY(0);
      opacity: 1;
    }
  }
}
