
// -----------------------------------------------------------------------------
// + Content combo
// -----------------------------------------------------------------------------


.content-combo {
  @include block-wrapper($cols: 10);
}

.content-combo__intro {

  @include when-larger-than('any') {
    display: block;
    position: relative;
    grid-column: 2 / -2;
    z-index: 1;
  }
  @include when-smaller-than('tablet-landscape') {
    padding-bottom: 2rem;
    margin-bottom: 2rem;
    border-bottom: 1px solid $grey-300;
  }
  @include when-larger-than('tablet-landscape') {
    padding: 0 0 4rem 0;
    grid-column: 1 / 6;
    display: grid;
    grid-template-columns: minmax(5rem, auto) minmax(0, 32.5rem) minmax(0, 7.5rem);
  }
  @include when-larger-than('desktop') {
    grid-column: 1 / 6;
    display: grid;
    grid-template-columns: minmax(5rem, auto) minmax(0, 32.5rem) minmax(0, 7.5rem);
  }

  .content-combo__intro-content {
    @include when-larger-than('any') {
      display: block;
      grid-column: 2 / -2;
    }
    @include when-larger-than('tablet-landscape') {
      padding: 5rem 0 5rem 0;
    }
    @include when-larger-than('desktop') {
      padding: 10rem 0 10rem 0;
    }
  }

}

.content-combo--has-image .content-combo__intro-content {
  @include when-larger-than('tablet-landscape') {
    min-height: 60rem;
  }
}

.content-combo__main {

  @include when-larger-than('any') {
    display: block;
    position: relative;
    grid-column: 2 / -2;
    padding: 0;
    z-index: 3;
  }

  @include when-larger-than('tablet-landscape') {
    grid-column: 6 / -1;
    display: grid;
    grid-template-columns: minmax(0, 5rem) minmax(0, 55rem) minmax(5rem, auto);
  }
  @include when-larger-than('desktop') {
    grid-column: 6 / -1;
    display: grid;
    grid-template-columns: minmax(0, 10rem) minmax(0, 50rem) minmax(5rem, auto);
  }

  .content-combo__main-content {
    @include when-larger-than('any') {
      display: block;
      grid-column: 3 / -3;
    }
    @include when-larger-than('tablet-landscape') {
      padding: 5rem 0 5rem 0;
    }
    @include when-larger-than('desktop') {
      padding: 10rem 0 10rem 0;
    }
  }

}

.content-combo__image {

  @include when-larger-than('any') {
    grid-column: 2 / -2;
    position: relative;
    padding-bottom: 40%;
    height: 0;
    z-index: 2;
    background-color: $blue-100;
    background-size: cover;
    margin-top: 4rem;
  }

  @include when-larger-than('tablet-landscape') {
    margin-top: -10rem;
  }
  @include when-larger-than('desktop') {
    margin-top: -20rem;
  }

}
