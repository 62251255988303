
.flickity-UI__navLeft,
.flickity-UI__navRight {
  pointer-events:none;
  z-index: 10;
  position: absolute;
  top: 0;
  height: 100%;
  width: 5rem;
  @include when-larger-than('tablet-landscape') {
    width: 7rem;
  }
  @include when-larger-than('desktop') {
    width: 15rem;
  }
}
.flickity-UI__navLeft {
  @include when-smaller-than('tablet-portrait') {
    width: 1.5rem;
  }
  transition: transform 300ms ease;
  transform: translateX(0);
  left: 0;
  background-image: linear-gradient(-270deg,
    rgba(255,255,255,1) 0%,
    rgba(255,255,255,0.98) 9%,
    rgba(255,255,255,0.95) 19%,
    rgba(255,255,255,0.88) 28%,
    rgba(255,255,255,0.80) 38%,
    rgba(255,255,255,0.71) 48%,
    rgba(255,255,255,0.61) 57%,
    rgba(255,255,255,0.50) 66%,
    rgba(255,255,255,0.39) 74%,
    rgba(255,255,255,0.29) 81%,
    rgba(255,255,255,0.20) 87%,
    rgba(255,255,255,0.12) 93%,
    rgba(255,255,255,0.05) 97%,
    rgba(255,255,255,0.02) 99%,
    rgba(255,255,255,0.00) 100%
  );
  @include when-smaller-than('tablet-portrait') {
    background-image: none;
  }
}
.flickity-UI__navRight {
  transition: transform 300ms 300ms ease;
  transform: translateX(0);
  right: 0;
  background-image: linear-gradient(-90deg,
    rgba(255,255,255,1) 0%,
    rgba(255,255,255,0.98) 9%,
    rgba(255,255,255,0.95) 19%,
    rgba(255,255,255,0.88) 28%,
    rgba(255,255,255,0.80) 38%,
    rgba(255,255,255,0.71) 48%,
    rgba(255,255,255,0.61) 57%,
    rgba(255,255,255,0.50) 66%,
    rgba(255,255,255,0.39) 74%,
    rgba(255,255,255,0.29) 81%,
    rgba(255,255,255,0.20) 87%,
    rgba(255,255,255,0.12) 93%,
    rgba(255,255,255,0.05) 97%,
    rgba(255,255,255,0.02) 99%,
    rgba(255,255,255,0.00) 100%
  );
  @include when-smaller-than('tablet-portrait') {
    //background-image: none;
  }
}

.flickity-UI__navRight.hide {
  @include when-larger-than('tablet-portrait') {
    transform: translateX(100%);
  }
  .flickity-UI__btnRight {
    transform: translateX(100%);
  }
}

.flickity-UI__navLeft.hide {
  @include when-larger-than('tablet-portrait') {
    transform: translateX(-100%);
  }
  .flickity-UI__btnLeft {
    transform: translateX(-100%);
  }
}

.flickity-UI__btnLeft,
.flickity-UI__btnRight {
  transition: transform 300ms ease;
  transform: translateX(0);
  appearance: none;
  pointer-events:auto;
  cursor: pointer;
  position: absolute;
  top: 5rem;
  height: 8rem;
  width: 6.5rem;
  background-color: $blue-300;
  border: none;
  box-shadow: 0px 0px 16px rgba(0,0,0,0.25);
  svg {
    fill: $white;
    transition: transform 300ms 200ms ease;
    display: block;
    margin: 0 auto;
  }
  &:hover {
    background-color: $blue-200;
    transition: all 300ms 200ms ease;
    svg {
      transform: scale(1.3);
    }
  }
  &:focus {
    outline:0;
  }
}

.flickity-UI__btnLeft {
  left: -1rem;
  svg {
    padding-left: 0.5rem;
  }
  &:hover {
    transform: translateX(1rem);
  }
}

.flickity-UI__btnRight {
  right: -1rem;
  svg {
    padding-right: 0.5rem;
  }
  &:hover {
    transform: translateX(-1rem);
  }
}
