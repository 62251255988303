

// -----------------------------------------------------------------------------
// Entry navigation used on entry index pages and taxonomy pages
// -----------------------------------------------------------------------------

nav.entry-nav {

  display: flex;
  flex-flow: row wrap;
  justify-content: flex-start;
  align-items: flex-start;
  margin: -1rem;
  padding: 2rem 0;

  .nav__link-list {
    flex: 1 1 auto;
    height: 4rem;
    margin: 1rem;
  }

  .nav__select {
    flex: 0 0 auto;
    height: 4rem;
    margin: 1rem;
  }

  .nav__button {
    flex: 0 0 auto;
    height: 4rem;
    margin: 1rem;
  }

}
