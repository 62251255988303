
@mixin card-ghost {
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: start;
  flex-wrap: nowrap;
  height: 100%;
  grid-column: span 2;
  .card__thumbnail {
    background-image: linear-gradient(90deg, $grey-200, rgba($white, 0.8));
    background-color: $grey-200;
    width: 100%;
    height: 0;
    padding-bottom: 50%;
  }
  .card__meta-top {
    display: block;
    height: 2.3rem;
    width: 30%;
    margin-top: 1.7rem;
    margin-bottom: 0.5rem;
    background-image: linear-gradient(90deg, $grey-200, rgba($white, 0.8));
    background-color: $grey-200;
  }
  .card__title {
    display: block;
    height: 2.3rem;
    width: 75%;
    margin-bottom: 0.5rem;
    background-image: linear-gradient(90deg, $grey-200, rgba($white, 0.8));
    background-color: $grey-200;
  }
  .card__subtitle {
    display: block;
    height: 2.3rem;
    width: 50%;
    background-image: linear-gradient(90deg, $grey-200, rgba($white, 0.8));
    background-color: $grey-200;
  }
  .card__meta-bottom {
    margin-top: auto;
    border: none;
    color: $blue-300;
    display: block;
    height: 2rem;
    width: 40%;
    background-image: linear-gradient(90deg, $grey-200, rgba($white, 0.8));
    background-color: $grey-200;
  }
}
