

// -------------------------------------------------------
// + CARD TYPES
// -------------------------------------------------------

@import "cards/types/ressourceCard";
@import "cards/types/eventCard";
@import "cards/types/ghostCard";

// -------------------------------------------------------
// + CARD LAYOUTS
// -------------------------------------------------------

// Default layout
// -------------------------------------------------------

.card {
  &.card--ressource {
    @include card-ressource--base;
    @include card-ressource--regular;
  }
  &.card--event {
    @include card-event--base;
    @include card-event--regular;
  }
  &.card--ghost {
    @include card-ghost;
  }
}

// Flickity layout
// -------------------------------------------------------

.flickInit .card {
  &:after {
    right: -2.5rem;
    @include when-larger-than('tablet-mid') {
     right: -2.5rem;
    }
  }
}

// Four-up
// -------------------------------------------------------

.block-card-rack--four-up {

  @include when-larger-than('desktop') {
    .card--ressource:nth-child(1) { @include card-ressource--large; }
    //.card--ressource:nth-child(2) { @include card-ressource--tall; }
    .card--event:nth-child(1) { @include card-ressource--large; }
    //.card--event:nth-child(2) { @include card-event--tall; }
  }

  @include when-larger-than('desktop') {
    .card--ressource:nth-child(4) { @include card-ressource--large; }
    //.card--ressource:nth-child(3) { @include card-ressource--tall; }
    .card--event:nth-child(4) { @include card-ressource--large; }
    //.card--event:nth-child(3) { @include card-event--tall; }
  }

}

// Two-up
// -------------------------------------------------------

.block-card-rack--two-up {

  @include when-larger-than('desktop') {
    .card--ressource:nth-child(1) { @include card-ressource--large; }
    //.card--ressource:nth-child(2) { @include card-ressource--tall; }
    .card--event:nth-child(1) { @include card-ressource--large; }
    //.card--event:nth-child(2) { @include card-event--tall; }
  }

}

// One-up
// -------------------------------------------------------

.block-card-rack--one-up {

  @include when-larger-than('desktop') {
    .card--ressource:nth-child(1) { @include card-ressource--large; }
    //.card--ressource:nth-child(2) { @include card-ressource--tall; }
    .card--event:nth-child(1) { @include card-ressource--large; }
    //.card--event:nth-child(2) { @include card-event--tall; }
  }

}
