

// -----------------------------------------------------------------------------
// + Components - Card block-card-rack
// -----------------------------------------------------------------------------

.block-card-rack {
  @include block-wrapper($cols: 10);
  @include block-spacing($place: 'both');
  &.block-card-rack--no-margin {
    margin-top: 0;
    margin-bottom: 0;
  }
}

.block-card-rack--deco::before {
  @include when-larger-than('tablet-mid') {
    content: '';
    display: block;
    position: absolute;
    top: 0; left: 0;
    width: 100%;
    height: 22rem;
    background-image: linear-gradient(180deg,
      rgba(248,248,248,1) 0%,
      rgba(248,248,248,0.98) 9%,
      rgba(248,248,248,0.95) 19%,
      rgba(248,248,248,0.88) 28%,
      rgba(248,248,248,0.80) 38%,
      rgba(248,248,248,0.71) 48%,
      rgba(248,248,248,0.61) 57%,
      rgba(248,248,248,0.50) 66%,
      rgba(248,248,248,0.39) 74%,
      rgba(248,248,248,0.29) 81%,
      rgba(248,248,248,0.20) 87%,
      rgba(248,248,248,0.12) 93%,
      rgba(248,248,248,0.05) 97%,
      rgba(248,248,248,0.02) 99%,
      rgba(248,248,248,0.00) 100%
    );
  }
  @include when-larger-than('tablet-mid')         { grid-column: 8 / end; }
  @include when-larger-than('tablet-landscape')   { grid-column: 7 / end; }
  @include when-larger-than('desktop')            { grid-column: 6 / end; }
}

.block-card-rack__title,
.block-card-rack__link {
  @include when-larger-than('any')                { grid-column: 2 / -2; }
  @include when-larger-than('tablet-mid')         { grid-column: 2 / 8; }
  @include when-larger-than('tablet-landscape')   { grid-column: 2 / 7; }
  @include when-larger-than('desktop')            { grid-column: 2 / 6; }
}

.block-card-rack__title {
  position: relative;
  z-index: 2;
  padding: 1rem 5rem 0 0;
  h1, h2, h3, h4, h5, h6 {
    color: $blue-300;
  }
  p {
    color: $blue-100;
  }
  @include when-smaller-than('tablet-portrait') {
    margin-top: 3rem;
    margin-bottom: 3rem;
  }
}

.block-card-rack__link {
  @include when-smaller-than('tablet-portrait') {
    margin-top: 2rem;
    margin-bottom: 3rem;
  }
}

.block-card-rack__NavWrap {
  @include when-larger-than ('any') {
    grid-column: 2 / -2;
    margin-top: -2rem;
  }
  @include when-larger-than ('tablet-portrait') {
    grid-column: 2 / -2;
    margin-top: 1rem;
    margin-bottom: -4rem;
  }
  @include when-larger-than ('tablet-landscape') {
    grid-column: 2 / 12;
    margin-top: 1rem;
    margin-bottom: -4rem;
  }
  @include when-larger-than ('desktop') {
    grid-column: 6 / 12;
    margin-top: 0;
    padding-left: 2rem;
    margin-bottom: -3rem;
  }
}

.block-card-rack__UIWrap {
  grid-column: start / end;
  z-index: 1;
  @include block-spacing($type: 'padding', $place: 'both', $values: 2 4 6);
}

.block-card-rack__items-wrap {
  @include block-wrapper;
}


// --------------------------------------------------------------
// + grid items are cards as defined in cards.scss
// --------------------------------------------------------------

.block-card-rack__items {
  grid-column: col-start / col-end;
  display: grid;
  grid-auto-flow: row;
  grid-template-columns: 2fr;
  grid-gap: 1.5rem;
  @include when-larger-than('tablet-mid') {
    grid-template-columns: repeat(4, 1fr);
    grid-gap: 3rem;
  }
  @include when-larger-than('desktop') {
    grid-template-columns: repeat(6, 1fr);
    grid-gap: 3rem;
  }
}

// -----------------------------------------------------------------------------
// + Flickity style overrides
// -----------------------------------------------------------------------------

.flickity-viewport {
  position: static;
}

.flickInit .block-card-rack__items {
  overflow: visible;
}
.flickInit .block-card-rack__items .card {
  width: 100%;
  max-width: 30rem;
  margin-right: 3rem;
  @include when-smaller-than('tablet-portrait') {
    margin-right: 3rem;
  }
}
