
// -----------------------------------------------------------------------------
// + Column layout for content blocks
// -----------------------------------------------------------------------------


.preset-cols {

  @include block-wrapper($cols: 10);
  @include when-smaller-than('tablet-landscape') { --margin: 1.25rem; }
  @include when-larger-than('tablet-landscape') { --margin: 2.5rem; }

  .preset-cols__wrapper {
    grid-column: 2 / -2;
    position: relative;
    display: flex;
    flex-flow: row wrap;
    margin: calc(var(--margin) * -1);
    .column {
      flex-grow: 1;
      flex-basis: calc((72rem - 100%) * 999); // Content query
      margin: var(--margin);
    }
  }
}

.preset-cols--align-left    { justify-content: flex-start; }
.preset-cols--align-center  { justify-content: center; }
.preset-cols--align-right   { justify-content: flex-end; }

.column {
    // &.column--width-100 { --colWidth: 100%;  }
    // &.column--width-75  { --colWidth: 75%;  }
    // &.column--width-50  { --colWidth: 50%;  }
    // &.column--width-33  { --colWidth: 33%;  }
    // &.column--width-25  { --colWidth: 25%;  }
}

@include when-smaller-than('tablet-landscape') {
  .column { max-width: 100%; }
}

@include when-larger-than('tablet-landscape') {
  .column {
      &.column--width-100 { max-width: #{ (120 * 100.0000 / 100) - 10 }rem; }
      &.column--width-75  { max-width: #{ (120 * 75.00000 / 100) - 10 }rem; }
      &.column--width-66  { max-width: #{ (120 * 66.66666 / 100) - 10 }rem; }
      &.column--width-50  { max-width: #{ (120 * 50.00000 / 100) - 10 }rem; }
      &.column--width-33  { max-width: #{ (120 * 33.33333 / 100) - 10 }rem; }
      &.column--width-25  { max-width: #{ (120 * 25.00000 / 100) - 10 }rem; }
  }
}

.column__wrapper {
  display: flex;
  flex-flow: row wrap;
  margin: -1rem calc(var(--margin) * -1);
  & > * {
    flex-grow: 1;
    margin: 1rem var(--margin);
    @include when-larger-than('tablet-landscape')  { flex-basis: calc((100rem - 100%) * 999); }
    @include when-smaller-than('tablet-landscape') { flex-basis: calc((50rem - 100%) * 999); }
    img {
      max-width: 100%;
    }
  }
}

.column {
  padding-bottom: 4rem;
}

.column_image {
  position: relative;
  picture:after {
    z-index: 0;
    display: block;
    position: absolute;
    content: "";
    background-color: $grey-300;
    top: 0; left: 0;
    width: 100%;
    height: 100%;
    transform: translate(-1.25rem, -1.25rem);
  }
  img {
    position: relative;
    z-index: 1;
  }
}

//.column--width-100 .column__wrapper > * { flex-basis: calc( ( #{ (120 * 100.0000 / 100) + 10 }rem - 100%) * 999); }
//.column--width-75  .column__wrapper > * { flex-basis: calc( ( #{ (120 * 75.00000 / 100) + 10 }rem - 100%) * 999); }
//.column--width-66  .column__wrapper > * { flex-basis: calc( ( #{ (120 * 66.66666 / 100) + 10 }rem - 100%) * 999); }
//.column--width-50  .column__wrapper > * { flex-basis: calc( ( #{ (120 * 50.00000 / 100) + 10 }rem - 100%) * 999); }
//.column--width-33  .column__wrapper > * { flex-basis: calc( ( #{ (120 * 33.33333 / 100) + 10 }rem - 100%) * 999); }
//.column--width-25  .column__wrapper > * { flex-basis: calc( ( #{ (120 * 25.00000 / 100) + 10 }rem - 100%) * 999); }
