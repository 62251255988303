
// -----------------------------------------------------------------------------
// Main site navigation
// -----------------------------------------------------------------------------

.site-nav {
  position: relative;
  display: block;
  width: 100%;
  z-index: 100;
  @include block-spacing($type: 'padding', $place: 'both', $values: 3 5 5);
  .top-bar {
    display: grid;
    position: relative;
    grid-template-columns: [start] minmax(5rem, auto) [col-start] minmax(0, 102.5rem) [col-end] minmax(2.5rem, auto) [end];
    @include when-smaller-than('tablet-portrait') {
      grid-template-columns: [start] 2.5rem [col-start] 1fr [col-end] 2.5rem [end];
    }
    &__content {
      grid-column: 2 / -2;
      display: flex;
      flex-flow: row nowrap;
      justify-content: space-between;
    }
  }
}

// -----------------------------------------------------------------------------

a.logo {
  display: block;
  text-decoration: none;
  background-image: none;
  h1 {
    display: block;
    width: 170px;
    height: 4rem;
    background-image: url(../img/ecegrenoble_logo.svg);
    background-repeat: no-repeat;
    background-size: contain;
    background-position: 0%;
    margin: 0;
    z-index: 5;
    position: relative;
  }
}

// -----------------------------------------------------------------------------

nav.main-nav {
  @include hide-visually("hide");
  @include when-larger-than('desktop') {
    @include hide-visually("unhide");
  }
  ul {
    display: block;
    list-style: none;
    height: 4rem;
    margin: 0;
    padding: 0;
    width: auto;
    li {
      display: inline-block;
      padding: 0;
      margin: 0rem 2rem;
      a {
        //padding: 0;
      }
    }
    li:last-child {
      margin-right: 0;
    }
  }
}


// + Navigation links
// ---------------------------------------------------------------------


a.nav-btn {
  @include link-reset;
  display: inline-block;
  padding: 0.5rem 0;
  margin: 0.5rem 0;
  position: relative;
  overflow: hidden;
  border-bottom: 2px solid transparent;
  transition: all 300ms;
  color: $blue-300;
  &:hover, &:focus, &:active {
    color: $link-hover-color;
    border-bottom: 2px solid $green-200;
   }
}

.site-nav--dark .main-nav a.nav-btn {
  &:hover, &:focus, &:active {
    border-bottom: 2px solid $white;
  }

}

// -----------------------------------------------------------------------------

button.phone-nav-button {
  @include hide-visually("unhide");
  @include when-larger-than('desktop') {
    @include hide-visually("hide");
  }
  z-index: 12;
  height: 48px;
  width: 48px;
  background: transparent;
  display: block;
  position: fixed;
  top: 5rem;
  right: 5rem;
  @include when-smaller-than('tablet-portrait') {
    top: 2.5rem;
    right: 3rem;
  }
  border: none;
  cursor: pointer!important;
  &:before,
  &:after {
    content: "";
    display: block;
    position: absolute;
    height: 2px;
    background-color: $blue-300;
    transition: all 300ms ease-in-out;
  }
  &:before {
    top: 20px;
    width: 24px;
    left: 12px;
  }
  &:after {
    bottom: 20px;
    width: 20px;
    left: 16px;
  }
  &:hover:after {
    width: 24px;
    left: 12px;
  }
  &.open:before {
    transform: translateY(3px) rotate(45deg);
  }
  &.open:after {
    transform: translateY(-3px) rotate(-225deg);
    width: 24px;
    left: 12px;
  }
  &:focus {
    outline:0;
  }
}
