
// -----------------------------------------------------------------------------
// Mobile site navigation
// -----------------------------------------------------------------------------

nav.phone-nav {
  @include hide-visually("unhide");
  @include when-larger-than('desktop') {
    @include hide-visually("hide");
  }
  position: fixed;
  top: 0;
  right: 0;
  overflow-x: scroll;
  max-width: 80vw;
  max-height: 100vh;
  z-index: 11;
  overflow: auto;
  background: $grey-100;
  transition: all 300ms ease-in-out;
  transform: translateX(100%);
  box-shadow: -2rem 0 4rem rgba(0,0,0,0);
  .logo {
    position: fixed;
    top: 5rem;
    left: 5rem;
    z-index: 12;
  }
  &.open {
    box-shadow: -2rem 0 4rem rgba(0,0,0,0.5);
    transform: translateX(0%);
  }
}

.phone-nav__content {
  display: flex;
  min-height: 100vh;
  flex-flow: column nowrap;
  padding: 10rem 10rem 5rem 5rem;
  .phone-nav__main-content {
    margin: 0;
  }
  .phone-nav__user-content {
    margin-top: 5rem;
  }
}

ul.phone-nav__main-content__links,
ul.phone-nav__user-content__links {
  display: block;
  list-style: none;
  padding: 0;
  li a {
    font-family: "Whitney A", "Whitney B";
    font-weight: 600;
    font-size: #{(21 / 12)}rem;
    letter-spacing: 0px;
    margin-top: 0;
    line-height: 2rem;
    background-image: none;
    background: none;
    border: none;
    text-shadow: unset;
    display: inline-block;
    position: relative;
    overflow: hidden;
    transition: all 300ms ease-in-out;
    background-color: transparent;
    outline: none;
    padding: 0.5rem 0;
    &:before {
      background-color: $grey-300;
    }
    &:after {
      display: none;
    }
    &:hover, &:focus, &:active {
      background-image: none;
      border: none;
      color: $green-300;
      &:after {
        display: none;
      }
    }
  }
}

ul.phone-nav__user-content__links li a {
  font-size: #{(18 / 12)}rem;
  padding: 0.25rem 0;
}


p.phone-nav__user-content__name {
  padding-bottom: 0.5rem;
  border-bottom: 1px solid $grey-400;
  @include font-style-meta-title;
}
