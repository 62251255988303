

// -----------------------------------------------------------------------------
//
// -----------------------------------------------------------------------------

div.nav__link-list {

  position: relative;
  display: flex;
  border-bottom: 0.5px solid rgba($blue-300,0.5);

  &.nav__link-list--no-border {
    border-bottom: none;
  }

  & > a.nav__link {
    border-bottom: 2px solid transparent;
    &.active {
      border-bottom: 2px solid $green-300;
    }
    &:hover {
      border-bottom: 2px solid $blue-300;
    }
  }
}

// -----------------------------------------------------------------------------
// Button used on pageNav.scss and entryNav.scss
// -----------------------------------------------------------------------------

a.nav__link {
  position: relative;
  display: block;
  font-family: "Whitney SC A", "Whitney SC B";
  text-transform: lowercase;
  font-size: #{(18 / 12)}rem;
  color: $blue-300;
  font-weight: 400;
  background-image: none;
  text-shadow: none;
  height: 4rem;
  border-bottom: 1.5px solid transparent;
  padding: 0;
  line-height: 3.8rem;
  margin: 0 1.3rem -1.5px 1.3rem;
  white-space: nowrap;
  &.active {
    font-weight: 500;
    color: $blue-300;
    border-bottom: 1.5px solid $green-300;
  }
  &:hover {
    transition: all 300ms ease-in;
    color: $blue-300;
    border-bottom: 1.5px solid $blue-300;
  }
}
