
// -----------------------------------------------------------------------------
// + Text only header
// -----------------------------------------------------------------------------

.text-only-header {
  @include block-spacing($place: 'both', $values: 3 3 3);
  @include block-wrapper($cols: 10);
}

.text-only-header__content {
  grid-column: 2 / -2;
  @include block-spacing($place: 'bottom', $type: 'margin', $values: 1 2 4);
  @include when-smaller-than('tabletportrait') {
    grid-column: col-start / col-end;
  }
}

.text-only-header__title-group {
  .text-only-header__title { color: $blue-200;}
  .text-only-header__subtitle { color: $blue-100;}
}

.text-only-header__intro {
  max-width: 55rem;
}

.text-only-header__image {
  grid-column: 2 / -2;
  height: 48rem;
  background-color: $blue-200;
  @include when-smaller-than('tabletportrait') {
    grid-column: col-start / col-end;
  }
  img {
    object-fit: cover;
    width: 100%;
    height: 100%;
  }
}
