
// -----------------------------------------------------------------------------
// Dropdown select used on entryNav.scss
// -----------------------------------------------------------------------------

div.nav__select {

  position: relative;
  display: flex;

  span.nav__select__label {
    display: block;
    position: relative;
    color: $blue-300;
    @include font-style-meta;
    font-weight: 500;
    height: 4rem;
    line-height: 4rem;
    padding: 0 1.5rem;
    margin-right: -1px;
    margin-bottom: 0!important;
    border: 1px solid darken($grey-400, 5%);
    background-color: $white;
  }


  select.nav__select__element {
    color: $cyan-300;
    @include font-style-meta;
    font-weight: 500;
    cursor: pointer;
    outline: none;
    appearance: none;
    height: 4rem;
    line-height: 4rem;
    padding: 0 3rem 0 1rem;
    margin-bottom: 0!important;
    background-color: $grey-100;
    border: 1px solid darken($grey-400, 5%);
    border-radius: 0;
    &:hover {
      background-color: $grey-200;
      transition: background-color 150ms ease-in;
    }
    &:focus {
      color: $blue-300;
    }
  }

}

div.nav__select--compact {
  span.nav__select__label {
    display: none;
  }
  select.nav__select__element {
    border: 1px solid darken($grey-400, 5%);
    background-color: $white;
    color: $blue-300;
  }
}

i.nav__select__icon {
  position: absolute;
  pointer-events: none;
  width: 1.5rem;
  height:1.5rem;
  top: 1.4rem;
  right: 1.25rem;
  svg {
    fill: $blue-300;
    width: 100%;
    height: 100%;
  }
}

i.nav__select__label-icon {
  pointer-events: none;
  display: inline-block;
  position: relative;
  width: 1.5rem;
  height:1.5rem;
  margin: 1.25rem 0;
  svg {
    position: absolute;
    fill: $blue-300;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
  }
}
