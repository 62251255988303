
fieldset {
  background-color: transparent;
  border: 0;
  margin-top: 5rem;
  padding: 0;
}

#bidon {
  display: none;
}

.form-message {
  margin: 1rem 0;
  opacity: 0;
  color: $blue-200;
  &.show{
    opacity: 1;
  }
}

legend {}

label {
  display: block;
  margin: 1rem 0;
  text-align: left;
  width: 100%;
  font-weight: 600;
  i {
    font-style: normal;
    color: $orange-300;
  }
}

label + small {
  display: block;
  margin-top: -1rem;
  margin-bottom: 1rem;
}

label.for-checkbox {
  margin: 2rem 0;
}

input,
select,
textarea {
  display: block;
  font-size: 1.5rem;
  color: $blue-200;
}

#{$all-text-inputs} {
  appearance: none;
  background-color: $grey-100;
  border-radius: 4px;
  border: 1px solid darken($grey-400, 10%);
  margin-bottom: 1rem;
  padding: 1rem;
  transition: border-color 300 ease-in-out;
  width: 100%;
  &:hover {
    border: 1px solid rgba($blue-100, 0.5);
  }
  &:focus {
    outline-width: 0;
    box-shadow: 0 0 5px rgba($green-300, 0.5);
    border: 1px solid $green-200;
  }
  &:disabled {
    pointer-events: none;
    cursor: not-allowed
  }
  &::placeholder {
    color: rgba($blue-100, 0.5);
  }
}

textarea.resize {
  resize: vertical;
}

[type="checkbox"],
[type="radio"] {
  display: inline;
  margin-right: 0.5rem;
  margin-bottom: 4px;
}

[type="file"] {
  margin-bottom: 2rem;
  width: 100%;
}

select {
  margin-bottom: 2rem;
  width: 100%;
}

[type="checkbox"],
[type="radio"],
[type="file"],
select {
  &:focus {
    //outline: $focus-outline;
    //outline-offset: $focus-outline-offset;
  }
}

// -----------------------------------------------------------------------------
// Light theme blue on white background
// -----------------------------------------------------------------------------


.contact-form,
.user-form {
  @include block-wrapper($cols: 10);
}

.contact-form__wrapper,
.user-form__wrapper {
  @include when-larger-than('any') {
    display: block;
    position: relative;
    grid-column: 2 / -2;
    z-index: 1;
  }
}

.contact-form form,
.user-form form {
  max-width: 65rem;
}

form {
  .error-list p {
    color: $orange-300;
  }
  ul.errors {
    list-style: none;
    padding: 0;
    margin: 1rem 0 0 0;
    li {
      font-style: italic;
      font-weight: 500;
      color: $orange-300;
      margin-bottom: 1rem;
      margin-top: -0.5rem;
      span {
        font-style: normal;
      }
    }
  }

  p.errors {
    margin: 1rem 0;
    font-style: italic;
    font-weight: 500;
    color: $orange-300;
  }

  hr {
    margin: 3rem 0;
  }
}


.submit__group {
  display: inline-block;
  margin-top: 1rem;
  button {
    margin-right: 1rem;
  }
  label {
    display: inline;
    white-space: nowrap;
  }
  span {
    margin-right: 1rem;
  }
}



.fieldset__group {
  position: relative;
  display: flex;
  flex-flow: column nowrap;
  width: 100%;
}

.fieldset__inline_group {
  @include when-larger-than('tablet-mid') {
    display: flex;
    flex-flow: row nowrap;
    justify-content: space-between;
    .fieldset__group {
      position: relative;
      flex: 0 1 calc(50% - 1rem);
    }
  }
}
