
.user-flow-wrap {
  background-color: $grey-200;
  background-image: url("../img/ece-croix-gris.png");
  background-position: left center;
  background-size: 100rem;
  background-repeat: no-repeat;
  display: flex;
  align-items: center;
  justify-content: center;
  min-height: calc(100vh - 42rem);
  .user-flow__form-holder {
    background-color: $white;
    box-shadow: 0px 0px 20px 1px rgba(0, 0, 0, 0.1);
    margin: 6rem 4rem;
    padding: 3rem 4rem;
    width: 100%;
    max-width: 40rem;
    border-radius: 4px;
  }
}

.flash-block {
  @include block-wrapper();
  .flash__wrapper {
    grid-column: 2/-2;
  }
}

[class*="flash-msg__"] {
  background-color: rgba($grey-300, 0.2);
  border: 1px solid $grey-200;
  border-radius: 4px;
  padding: 1rem 2rem;
  margin-bottom: 0.5rem;
  p {
    margin: 0;
  }
}

.flash-msg__notice {
  background-color: rgba($green-300, 0.2);
  border: 1px solid $green-200;
}

.flash-msg__error {
  background-color: rgba($orange-300, 0.2);
  border: 1px solid $orange-200;
}
