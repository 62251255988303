
.intro-combo {

  @include block-wrapper($cols: 10);

  .intro-combo__wrapper {
    grid-column: 2 / -2;
    display: block;
    @include when-smaller-than('tablet-landscape') {
      padding-bottom: 2rem;
      margin-bottom: 2rem;
      border-bottom: 1px solid $grey-300;
    }
  }

&.intro-combo__horizontal-line {
  position: relative;
  padding-bottom: 7rem;
  &:after {
    position: absolute;
    bottom: -7rem;
    left: 50%;
    content: "";
    display: block;
    width: 1px;
    height: 14rem;
    background-color: darken($grey-400, 5%);
  }
}

  .intro-combo__holder {
    @include when-larger-than('tablet-portrait') {
      &.intro-combo--width-100 { max-width: 100.0%; }
      &.intro-combo--width-75  { max-width: 100.0%; }
      &.intro-combo--width-66  { max-width: 75.00%; }
      &.intro-combo--width-50  { max-width: 75.00%; }
      &.intro-combo--width-33  { max-width: 50.00%; }
      &.intro-combo--width-25  { max-width: 50.00%; }
    }
    @include when-larger-than('tablet-landscape') {
      &.intro-combo--width-100 { max-width: 100.0%; }
      &.intro-combo--width-75  { max-width: 75.00%; }
      &.intro-combo--width-66  { max-width: 66.66%; }
      &.intro-combo--width-50  { max-width: 50.00%; }
      &.intro-combo--width-33  { max-width: 33.33%; }
      &.intro-combo--width-25  { max-width: 25.00%; }
    }
  }
}
