
// -----------------------------------------------------------------------------
// + Text only header
// -----------------------------------------------------------------------------

.large-image-header {
  display: grid;
  position: relative;
  grid-template-columns: [start] minmax(5rem, auto) [col-start] repeat(12,  minmax(0, 10rem)) [col-end] minmax(5rem, auto) [end];
  @include when-smaller-than('tablet-portrait') {
    grid-template-columns: [start] 2.5rem [col-start] 1fr [col-end] 2.5rem [end];
  }
  @include block-spacing($place: 'both', $values: 4 4 6);
  min-height: 0;
  min-width: 0;
  z-index: 1;
}

.large-image-header__content {
  grid-column: 3 / -3;
  //@include block-spacing($place: 'bottom', $type: 'margin', $values: 2 2 2);
  @include when-smaller-than('tabletportrait') {
    grid-column: 2 / -2;
  }
}

.large-image-header__title-group {
  @include when-smaller-than('tablet-landscape') {
    br { display: none; }
  }
  .large-image-header__title { color: $blue-200;}
  .large-image-header__subtitle { color: $blue-100;}
}

.large-image-header__image {
  grid-column: 2 / -2;
  background-color: $blue-200;
  @include when-smaller-than('tabletportrait') {
    grid-column: 2 / -2;
  }
  height: 0px;
  padding-bottom: 33.33%;
  position: relative;
  img {
    position: absolute;
    top: 0; left: 0;
    object-fit: cover;
    width: 100%;
    height: 100%;
  }
}
