
// TPL Combo - Title Paragraph Link Combo
// --------------------------------------

.ttl-combo {

  .ttl-combo__title-group {
    margin-bottom: 2rem;
    & > * {
      display: inline;
    }
  }

  .ttl-combo__title {
    margin: 0;
    padding: 0;
  }
  .ttl-combo__subtitle {
    margin: 0;
    padding: 0;
  }
  .ttl-combo__text {
    max-width: 35rem;
    //padding-right: 15rem;
    & > *:last-child {
      //margin-bottom: 0;
    }
  }
  .ttl-combo__links {
    padding: 0;
    margin-top: 2rem;
  }
  .ttl-combo__title-group + .ttl-combo__links {
    margin-top: 4rem;
    @include when-smaller-than('tablet-portrait') {
      margin-top: 2rem;
    }
  }
}

.ttl-combo.ttl-combo--dark {
  .ttl-combo__title { color: $green-200; }
  .ttl-combo__subtitle,
  .ttl-combo__text,
  .ttl-combo__link.btn {
    color: $white;
  }
}


[class$="--dark"] .ttl-combo,
.ttl-combo--dark {
  .ttl-combo__title {
    color: $green-200;
  }
  .ttl-combo__subtitle,
  .ttl-combo__text {
    color: $white;
  }
}
