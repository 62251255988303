
@mixin card__date {
  .card__date {
    @include font-style-date;
    display: block;
    position: absolute;
    bottom: 0; left: 0;
    padding: 0.5rem 3rem 0 2rem;
    background-color: $white;
    z-index: 10;
  }
}
