*,
*:after,
*:before { box-sizing: border-box; }

* { -webkit-overflow-scrolling: touch; }

html, body {
  height: 100%;
  overflow-x: hidden;
}

html {
  font-size: 12px; //62.5%;
}

body {
  font-size: 1.5rem;
  padding: 0;
  margin: 0;
  background-color: $white;
}

.site-wrap {
  position: relative;
  max-width: 1920px;
  margin: 0 auto;
  background-color: $white;
  @include when-larger-than(1920px) {
    overflow: hidden;
  }
}

.sr-only,
.screen-reader-only {
    border: 0;
    clip: rect(0,0,0,0);
    height: 1px;
    margin: -1px;
    overflow: hidden;
    padding: 0;
    position: absolute;
    width: 1px;
}

.no-scroll {
  overflow: hidden;
}

picture {
  display: block;
}

img {
  text-indent: 100%;
  white-space: nowrap;
  overflow: hidden;
}

img.lazyloading {
	filter: blur(10px);
	transition: filter 300ms;
}

img.lazyloaded {
	filter: blur(0px);
}

#instantclick-bar {
  background: $green-200;
}

// -----

.fonts-loading body {
  visibility: hidden;
}

.fonts-loaded body,
.fonts-failed body {
  visibility: visible;
}

// -----

.redactor-dropdown-format .redactor-dropdown-item-titre-h3 {
  font-weight: bold;
  font-size: 22px;
}
.redactor-dropdown-format .redactor-dropdown-item-titre-h4 {
  font-weight: bold;
}
