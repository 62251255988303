
// -----------------------------------------------------------------------------
// + Navigation for taxonomy archive pages
// -----------------------------------------------------------------------------

.taxonomy-nav {
  display: grid;
  position: relative;
  grid-template-columns: minmax(5rem, auto) minmax(0, 5rem) repeat(10,  minmax(0, 10rem)) minmax(0, 5rem) minmax(5rem, auto);
  @include when-smaller-than('tablet-portrait') {
    grid-template-columns: 2.5rem 1fr 2.5rem;
  }
  @include block-spacing($place: 'both', $type: 'margin', $values: 0 0 0);
}

.taxonomy-nav__wrapper {
  background-color: $grey-100;
  display: grid;
  position: relative;
  grid-template-columns: 2.5rem minmax(0, 10rem) 1fr minmax(0, 10rem) 2.5rem;
  @include when-smaller-than('desktop') {
    grid-template-columns: 2.5rem minmax(0, 5rem) 1fr minmax(0, 5rem) 2.5rem;
  }
  @include when-smaller-than('tablet-landscape') {
    grid-template-columns: 2.5rem minmax(0, 0) 1fr minmax(0, 0) 2.5rem;
  }
  @include block-spacing($place: 'both', $type: 'padding', $values: 2 2 2);
  @include when-larger-than('any') {
    grid-column: 2 / -2;
  }
  @include when-smaller-than('tabletportrait') {
    grid-column: 1 / -1;
  }
}

.taxonomy-nav__content {
  grid-column: 3 / -3;
}

.taxonomy_link_list {
  @include when-smaller-than('tablet-landscape') {
    display: none!important;
  }
}

.taxonomy_select_list {
  @include when-larger-than('tablet-landscape') {
    display: none!important;
  }
}
