
// -----------------------------------------------------------------------------

@mixin block-wrapper($cols: 10, $margins: true, $width: 100) {
  display: grid;
  position: relative;
  @if $margins {
    $colMaxWidth: #{$width / $cols}rem;
    grid-template-columns: [start] minmax(5rem, auto) [col-start] repeat($cols,  minmax(0, $colMaxWidth)) [col-end] minmax(5rem, auto) [end];
    @include when-smaller-than('tablet-portrait') {
      grid-template-columns: [start] 2.5rem [col-start] 1fr [col-end] 2.5rem [end];
    }
  }
  @else {
    $colMaxWidth: #{$width / $cols}rem;
    grid-template-columns: repeat($cols, minmax(0, $colMaxWidth));
    @include when-smaller-than('tablet-portrait') {
      grid-template-columns: [col-start] 1fr [col-end];
      max-width: 100%;
    }
  }
}

// -----------------------------------------------------------------------------

@mixin block-spacing($type: 'margin', $place: 'both', $values: 2 4 8, $important: null) {
  @if $place == 'both' {
    #{$type}-top: #{nth($values, 2)}rem #{$important};
    #{$type}-bottom: #{nth($values, 2)}rem #{$important};
    @include when-larger-than('tablet-landscape') {
      #{$type}-top: #{nth($values, 3)}rem #{$important};
      #{$type}-bottom: #{nth($values, 3)}rem #{$important};
    }
    @include when-smaller-than('tablet-portrait') {
      #{$type}-top: #{nth($values, 1)}rem #{$important};
      #{$type}-bottom: #{nth($values, 1)}rem #{$important};
    }
  }
  @elseif $place == 'sides' {
    #{$type}-left: #{nth($values, 2)}rem #{$important};
    #{$type}-right: #{nth($values, 2)}rem #{$important};
    @include when-larger-than('tablet-landscape') {
      #{$type}-left: #{nth($values, 3)}rem #{$important};
      #{$type}-right: #{nth($values, 3)}rem #{$important};
    }
    @include when-smaller-than('tablet-portrait') {
      #{$type}-left: #{nth($values, 1)}rem #{$important};
      #{$type}-right: #{nth($values, 1)}rem #{$important};
    }
  }
  @else {
    #{$type}-#{$place}: #{nth($values, 2)}rem #{$important};
    @include when-larger-than('tablet-landscape') {
      #{$type}-#{$place}: #{nth($values, 3)}rem #{$important};
    }
    @include when-smaller-than('tablet-portrait') {
      #{$type}-#{$place}: #{nth($values, 1)}rem #{$important};
    }
  }

}

// -----------------------------------------------------------------------------
// + Spacing options
// -----------------------------------------------------------------------------

.mt--12 { @include block-spacing($type: 'margin', $place: 'top', $values: 6 8 12, $important: '!important'); }
.mt--10 { @include block-spacing($type: 'margin', $place: 'top', $values: 6 8 10, $important: '!important'); }
.mt--8  { @include block-spacing($type: 'margin', $place: 'top', $values: 6 6 8, $important: '!important'); }
.mt--6  { @include block-spacing($type: 'margin', $place: 'top', $values: 4 6 6, $important: '!important'); }
.mt--4  { @include block-spacing($type: 'margin', $place: 'top', $values: 2 4 4, $important: '!important'); }
.mt--3  { @include block-spacing($type: 'margin', $place: 'top', $values: 2 3 3, $important: '!important'); }
.mt--2  { @include block-spacing($type: 'margin', $place: 'top', $values: 2 2 2, $important: '!important'); }
.mt--0  { @include block-spacing($type: 'margin', $place: 'top', $values: 0 0 0, $important: '!important'); }

.mb--12 { @include block-spacing($type: 'margin', $place: 'bottom', $values: 6 8 12, $important: '!important'); }
.mb--10 { @include block-spacing($type: 'margin', $place: 'bottom', $values: 6 8 10, $important: '!important'); }
.mb--8  { @include block-spacing($type: 'margin', $place: 'bottom', $values: 6 6 8, $important: '!important'); }
.mb--6  { @include block-spacing($type: 'margin', $place: 'bottom', $values: 4 6 6, $important: '!important'); }
.mb--4  { @include block-spacing($type: 'margin', $place: 'bottom', $values: 2 4 4, $important: '!important'); }
.mb--3  { @include block-spacing($type: 'margin', $place: 'bottom', $values: 2 3 3, $important: '!important'); }
.mb--2  { @include block-spacing($type: 'margin', $place: 'bottom', $values: 2 2 2, $important: '!important'); }
.mb--0  { @include block-spacing($type: 'margin', $place: 'bottom', $values: 0 0 0, $important: '!important'); }

.pt--12 { @include block-spacing($type: 'padding', $place: 'top', $values: 6 8 12, $important: '!important'); }
.pt--10 { @include block-spacing($type: 'padding', $place: 'top', $values: 6 8 10, $important: '!important'); }
.pt--8  { @include block-spacing($type: 'padding', $place: 'top', $values: 6 6 8, $important: '!important'); }
.pt--6  { @include block-spacing($type: 'padding', $place: 'top', $values: 4 6 6, $important: '!important'); }
.pt--4  { @include block-spacing($type: 'padding', $place: 'top', $values: 4 4 4, $important: '!important'); }
.pt--3  { @include block-spacing($type: 'padding', $place: 'top', $values: 3 3 3, $important: '!important'); }
.pt--2  { @include block-spacing($type: 'padding', $place: 'top', $values: 2 2 2, $important: '!important'); }
.pt--0  { @include block-spacing($type: 'padding', $place: 'top', $values: 0 0 0, $important: '!important'); }

.pb--12 { @include block-spacing($type: 'padding', $place: 'bottom', $values: 6 8 12, $important: '!important'); }
.pb--10 { @include block-spacing($type: 'padding', $place: 'bottom', $values: 6 8 10, $important: '!important'); }
.pb--8  { @include block-spacing($type: 'padding', $place: 'bottom', $values: 6 6 8, $important: '!important'); }
.pb--6  { @include block-spacing($type: 'padding', $place: 'bottom', $values: 4 6 6, $important: '!important'); }
.pb--4  { @include block-spacing($type: 'padding', $place: 'bottom', $values: 4 4 4, $important: '!important'); }
.pb--3  { @include block-spacing($type: 'padding', $place: 'bottom', $values: 3 3 3, $important: '!important'); }
.pb--2  { @include block-spacing($type: 'padding', $place: 'bottom', $values: 2 2 2, $important: '!important'); }
.pb--0  { @include block-spacing($type: 'padding', $place: 'bottom', $values: 0 0 0, $important: '!important'); }

// -----------------------------------------------------------------------------
// + Alignement options
// -----------------------------------------------------------------------------

// TODO HERE : RESPONSIVE IMAGES FOR REDACTOR Content
// MAX WIDTH + FOLLOW TEXT ALIGN RULES.
// Test CASE ON LOGOS TO IMPLEMENT

.text-align--left   {
  text-align: left;
  img {
    max-width: 100%;
    margin: 0 auto 0 0;
  }
}
.text-align--center {
  text-align: center;
  img {
    max-width: 100%;
    margin: 0 auto 0 auto;
  }
}
.text-align--right  {
  text-align: right;
  img {
    max-width: 100%;
    margin: 0 0 0 auto;
  }
}

.block-align--left   { margin: 0 auto 0 0; }
.block-align--center { margin: 0 auto 0 auto; }
.block-align--right  { margin: 0 0 0 auto; }
